<div [ngClass]="oscCodes.includes(gdSession?.oscConfig?.oscCode) ? 'tofel-modal-header' : 'modal-header'" tabindex="0">
    <h3 mat-dialog-title [ngClass]="oscCodes.includes(gdSession?.oscConfig?.oscCode) ? 'tofel-dialog-title' : 'mat-mdc-dialog-title mdc-dialog__title'">
        Alipay+
    </h3>
    <button mat-icon-button class="close-button" aria-label="Close modal" (click)="exit()" role="button" tabindex="0">
        <mat-icon class="close-icon" aria-hidden="true">close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p>
        After clicking on the Alipay+ payment button, you will be presented with the option to pay in your local currency
        using any one of many popular local e-wallets preferred by customers in China, Hong Kong, Indonesia, Malaysia,
        the Philippines, South Korea, and Thailand including but not limited to:
    </p>
    <ul style="list-style-type: none">
        <li>AlipayChina (China)</li>
        <li>AlipayHK (Hong Kong, SAR)</li>
        <li>KaKao Pay (South Korea)</li>
        <li>Kredivo (Indonesia)</li>
        <li>Gcash (The Philippines)</li>
        <li>Touch'n Go (Malaysia)</li>
        <li>Rabbit Line Pay (Thailand)</li>
        <li>Truemoney (Thailand)</li>
        <li>Dana (Indonesia)</li>
        <li>Akulaku Paylater (Indonesia)</li>
        <li>Akulaku Paylater (Philippines)</li>
    </ul>
    <p>Support for additional e-wallets, payment methods, and countries may also be expanded in the future.</p>

    <ul class="row pt-5 no-bullets">
        <li class="col-md-2 col-8 pb-4">
            <img class="alipay" src="../../assets/img/AlipayPlus.png" alt="Alipay+ logo, a platform for local payment methods" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipay.png" alt="Alipay logo representing China" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipayhk.png" alt="Alipay HK logo representing Hong Kong" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/kakoapay.png" alt="KaKao Pay logo representing South Korea" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/gcash.png" alt="Gcash logo representing the Philippines" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/touchngo.png" alt="Touch 'n Go eWallet logo representing Malaysia" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/rabbitlinepay.png" alt="Rabbit LINE Pay logo representing Thailand" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/truemoney.png" alt="Truemoney logo representing Thailand" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/dana.jpg" alt="DANA logo representing Indonesia" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/akulakuPaylater.png" alt="Akulaku Paylater logo representing Indonesia and the Philippines" tabindex="0" />
        </li>
        <li class="col-md-1 col-4 pb-4">
            <img class="alipay-img" src="../../assets/img/alipay/Kredivo.png" alt="Kredivo" tabindex="0" />
        </li>
    </ul>
</mat-dialog-content>
