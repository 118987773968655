<div
  id="shipping-contact-info"
  [ngClass]="oscCodes.includes(spcOscCode)?'card rounded-card tofel-receiving-information':'card rounded-card receiving-information'"
  *ngIf="gd?.oscConfig?.displayShippingFlds"
  tabindex="-1"
  role="document"
>
  <div class="card-body shpngcontct {{shpngcontct_mobile}}" *transloco="let t">
    <div class="order_info__shpngcontct">
      <h2 class="shipping__title_infor font_family__narrow">{{t('shipping.contact')}}</h2>
      <div class="expand__shipping_info {{caret_expand_lrgscrn}} {{caret_expand_lrgscrn_collapse}}" (click)="expandShippingInfo()">
        <span class="shipping__title_infor_expand font_family__standard">{{t('order.expand.info')}}</span>
        <img src="../../assets/svg/caret_down.svg" alt="down"/>
      </div>
    </div>
    <div class="mt-2 shipping__text_font_types {{caret_expand}} font_family__standard">
      <span
        >{{t('shipping.contact.text')}}</span
      >
    </div>
    <div class="shipping__text_address shipping__text_address_details {{caret_expand}} font_family__standard">
      <span *ngIf="orderData?.shippingAddress?.firstName">{{
        orderData?.shippingAddress?.firstName
      }}</span
      >&nbsp;

      <span *ngIf="orderData?.shippingAddress?.lastName">{{
        orderData?.shippingAddress?.lastName
      }}</span>
    </div>
    <div class="shipping__text_address_details">
      <div
        *ngIf="orderData?.shippingAddress?.company"
        class="shipping__text_address {{caret_expand}} font_family__standard"
      >
        {{ orderData?.shippingAddress?.company }}
      </div>
      <div class="shipping__text_address {{caret_expand}} font_family__standard">
        {{ orderData?.shippingAddress?.addressLine1 }}
      </div>
      <div class="shipping__text_address {{caret_expand}} font_family__standard">
        {{ orderData?.shippingAddress?.addressLine2 }}
      </div>
      <div class="address_multi_group {{caret_expand}}">
        <div class="shipping__text_address {{caret_expand}} font_family__standard">
          {{ orderData?.shippingAddress?.city }}
          {{ orderData?.shippingAddress.region }}
        </div>
        <div class="shipping__text_address font_family__standard">
          {{ orderData?.shippingAddress?.postalCode }}
        </div>
        <div class="shipping__text_address font_family__standard">
          {{ orderData?.shippingAddress?.country }}
        </div>
      </div>
    </div>
    <div
      class="mt-2 {{caret_expand}}"
      *ngIf="orderData?.shippingContact?.phoneNumber"
    >
      <dl class="dl-con">
      <dt>
      <span class="shipping__text_address_small font_family__standard">
        <!-- <fa-icon [icon]="faPhone"></fa-icon> -->
        {{t('order.phone')}}
      </span>
      </dt>
      <dd>
      <span class="shipping__text_address font_family__standard">
        {{ orderData?.shippingContact?.phoneNumber }}
      </span>
      </dd>
      </dl>
    </div>
    <div
      *ngIf="orderData?.shippingContact?.email"
      class="{{caret_expand}}"
    >
      <dl class="dl-con dl-dd-email">
      <dt>
      <span class="shipping__text_address_small font_family__standard">
        <!-- <fa-icon [icon]="faEnvelope"></fa-icon> -->
        {{t('order.email')}}
      </span>
      </dt>
      <dd>
      <span class="shipping__text_address font_family__standard">
        {{ orderData?.shippingContact?.email }}
      </span>
      </dd>
      </dl>
    </div>
  </div>
  <div class="collapse_section_shpngInfo {{caret_expand}}" *transloco="let t">
    <div class="expand__shipping_info {{caret_expand_lrgscrn}}" (click)="collapseShippingInfo()">
      <span class="shipping__title_infor_expand font_family__standard">{{t('order.collapse.info')}}</span>
      <img src="../../assets/svg/caret_up.svg" alt="up"/>
    </div>
  </div>
</div>
