<form [formGroup]="form">
  <ng-container *transloco="let t">
    <div class="row billing_address__form">
      <div
        class="col-lg-12 pb-3"
        *ngIf="gd?.oscConfig?.displayShippingAsBillingAddr"
      >
        <div class="billing_address__check">
          <input
            type="checkbox"
            id="sameAsShippingContactAddress"
            name="sameAsShippingContactAddress"
            formControlName="sameAsShippingContactAddress"
            (change)="sameadrchkchange($event)"
          />
          <label
            class="ps-2 address_label font_family__standard"
            for="sameAsShippingContactAddress"
            >{{ t(addrLabel) }}</label
          >
        </div>
      </div>
      <div class="col-lg-12 pb-3">
        <label for="streetAddress1" class="control-label"
          ><span class="bilng_adrs_details__label font_family__standard">{{t('billing.address.street1')}}</span
          ><span class="required-asterisk">*</span></label
        >
        <input
          id="streetAddress1"
          name="streetAddress1"
          class="form-control form-control-md"
          formControlName="streetAddress1"
          required
        />
        <span
          *ngIf="
            (form.controls['streetAddress1'].hasError('required') ||
              form.controls['streetAddress1'].hasError('minLength')) &&
            form.controls['streetAddress1'].touched
          "
          class="text-danger"
        >
          {{t('billing.error.address.street1')}}</span
        >
      </div>
      <div class="col-lg-12 pb-3">
        <label
          for="streetAddress2"
          class="control-label bilng_adrs_details__label font_family__standard"
          >{{t('billing.address.street2')}}</label
        >
        <input
          id="streetAddress2"
          name="streetAddress2"
          class="form-control form-control-md"
          formControlName="streetAddress2"
        />
      </div>
      <div class="col-lg-12 pb-3">
        <label for="city" class="control-label"
          ><span class="bilng_adrs_details__label font_family__standard"> {{t('billing.address.city')}}</span
          ><span class="required-asterisk">*</span></label
        >
        <input
          id="city"
          name="city"
          class="form-control form-control-md"
          formControlName="city"
          required
        />
        <span
          *ngIf="
            form.controls['city'].hasError('required') &&
            form.controls['city'].touched
          "
          class="text-danger"
        >
        {{t('billing.error.address.city')}}</span
        >
      </div>
      <div class="col-lg-12 pb-3">
        <label for="country__select" class="control-label"
          ><span class="bilng_adrs_details__label font_family__standard">{{t('billing.error.address.country')}}</span
          ><span class="required-asterisk">*</span></label
        >
        <select
          id="country__select"
          formControlName="country"
          required
          class="form-select form-select-md"
          (change)="changeCountry()"
        >
          <option selected disabled value="">{{t('billing.address.country.select')}}</option>
          <option *ngFor="let country of countryList" [value]="country.id">
            {{ country.name }}
          </option>
        </select>
        <span
          *ngIf="
            (form.controls['country'].hasError('required') ||
              form.controls['country'].hasError('minLength')) &&
            form.controls['country'].touched
          "
          class="text-danger"
        >
          {{t('billing.error.address.country')}}</span
        >
      </div>
      <div
        class="col-lg-12 pb-3"
        *ngIf="
          selectedCountry === 'US' || selectedCountry === 'CA';
          else region
        "
      >
        <label
          for="province"
          *ngIf="selectedCountry === 'US'"
          class="control-label"
        >
          <span class="bilng_adrs_details__label font_family__standard">{{t('billing.address.state')}}</span><span class="required-asterisk">*</span></label
        >
        <label
          for="province"
          *ngIf="selectedCountry === 'CA'"
          class="control-label"
        >
          <span class="bilng_adrs_details__label font_family__standard">{{t('billing.address.province')}}</span
          ><span class="required-asterisk">*</span></label
        >
        <select
          id="province"
          *ngIf="selectedCountry === 'US' || 'CA'"
          formControlName="region"
          class="form-select form-select-md"
        >
          <option selected value="">{{t('billing.address.country.select')}}</option>
          <option *ngFor="let region of regionList" [value]="region.id">
            {{ region.name }}
          </option>
        </select>
        <span
          *ngIf="
            (form.controls['region'].hasError('required') ||
              form.controls['region'].hasError('minLength')) &&
            form.controls['region'].touched
          "
          class="text-danger"
        >
          You must select a
          {{ selectedCountry === "US" ? t('billing.address.state') : t('billing.address.province') }}.</span
        >
      </div>
      <ng-template #region>
        <div class="col-lg-12 pb-3">
          <label for="region" class="bilng_adrs_details__label font_family__standard"
            >{{t('billing.address.state.both')}}</label
          >
          <input
            id="region"
            name="region"
            class="form-control form-control-md"
            formControlName="region"
          />
        </div>
      </ng-template>
      <div
        class="col-lg-12 pb-3"
        *ngIf="
          selectedCountry === 'US' || selectedCountry === 'CA';
          else postal
        "
      >
        <label for="zipcode" class="control-label">
          <span class="bilng_adrs_details__label font_family__standard">{{ selectedCountry === "US" ? t('billing.address.zip') : t('billing.address.postal') }} Code</span>
          <span class="required-asterisk">*</span>
        </label>
        <input
          id="zipcode"
          name="zipcode"
          class="form-control form-control-md"
          formControlName="zipcode"
        />
        <span
          *ngIf="
            form.controls['zipcode'].hasError('required') &&
            form.controls['zipcode'].touched
          "
          class="text-danger"
        >
          You must enter your
          {{ selectedCountry === "US" ? t('billing.address.zip') : t('billing.address.postal') }} code.</span
        >
        <span
          *ngIf="form.controls['zipcode'].hasError('minlength')"
          class="text-danger"
        >
          {{ selectedCountry === "US" ? t('billing.address.zip') : t('billing.address.postal') }} Code must contain at
          least {{ selectedCountry === "US" ? "5" : "6" }} numeric
          characters.</span
        >
        <span
          *ngIf="form.controls['zipcode'].hasError('maxlength')"
          class="text-danger"
        >
          {{ selectedCountry === "US" ? t('billing.address.zip') : t('billing.address.postal') }} {{t('billing.error.zip.max.length')}}</span
        >
        <span
          *ngIf="form.controls['zipcode'].hasError('pattern')"
          class="text-danger"
        >
          {{ selectedCountry === "US" ? t('billing.address.zip') : t('billing.address.postal') }} {{t('billing.error.zip.pattern.invalid')}}</span
        >
      </div>
      <ng-template #postal>
        <div class="col-lg-12 pb-3">
          <label for="zipcode" class="bilng_adrs_details__label font_family__standard">
            {{t('billing.address.zip.both')}}
          </label>
          <input
            class="form-control form-control-md"
            type="text"
            name="zip-postalcode"
            id="zipcode"
            maxlength="10"
            formControlName="zipcode"
          />
        </div>
      </ng-template>
      <div class="col-lg-12 pb-3">
        <label for="company" class="control-label bilng_adrs_details__label font_family__standard">{{t('billing.address.company')}}</label
        >
        <input
          class="form-control form-control-md"
          type="text"
          name="company"
          id="company"
          formControlName="company"
        />
      </div>
      <div class="col-lg-12 pb-3">
        <label for="phoneNumber" class="control-label bilng_adrs_details__label font_family__standard"
          >{{t('billing.address.phone')}}
          <ng-container *ngIf="isRequiredField('phoneNumber')">
            <span class="required-asterisk"> * </span>
          </ng-container>
        </label>
        <input
          class="form-control form-control-md"
          type="tel"
          name="phoneNumber"
          id="phoneNumber"
          formControlName="phoneNumber"
        />
        <span
          *ngIf="
            form.controls['phoneNumber'].hasError('pattern') &&
            form.controls['phoneNumber'].touched
          "
          class="text-danger"
        >
        {{t('billing.error.card.phone.number')}}</span
        >
        <span
          *ngIf="
            isRequiredField('phoneNumber') &&
            form.controls['phoneNumber'].touched &&
            !form.controls['phoneNumber'].value
          "
          class="text-danger"
        >
          {{t('billing.error.card.phone.required')}}</span
        >
      </div>
      <div class="col-lg-12 pb-3">
        <label for="email" class="control-label bilng_adrs_details__label font_family__standard"
          >{{t('billing.address.email')}}
          <ng-container *ngIf="isRequiredField('email')">
            <span class="required-asterisk"> *</span>
          </ng-container>
        </label>
        <input
          class="form-control form-control-md"
          type="email"
          name="email"
          id="email"
          formControlName="email"
        />
        <span
          *ngIf="
            form.controls['email'].hasError('pattern') &&
            form.controls['email'].touched
          "
          class="text-danger"
        >
          {{t('billing.error.card.email.valid')}}</span
        >

        <span
          *ngIf="
            isRequiredField('email') &&
            form.controls['email'].touched &&
            !form.controls['email'].value
          "
          class="text-danger"
        >
          {{t('billing.error.card.email.required')}}</span
        >
      </div>
    </div>
  </ng-container>
</form>
