
<div class="tooltiptext">
    <button type="button" class="i-btn" (click)="loadingTooltip()" tabindex="0">
        <img src="../../assets/svg/cvvtip.svg" alt="CVV information"/>
        <span class="tooltiptextcvv"></span>
    </button>
</div>
<ng-template #cvvModalPopUp let-modal *transloco="let t">
    <div *ngIf="!oscCodes.includes(gd.oscConfig.oscCode)" class="modal_content__tooltip">
        <div class="tooltip__cvv">
            <img src="../../assets/svg/cvv_tooltip_modal.svg" alt="" />
            <h4 id="cvv-dialog-title" class="tooltip_cvv_title"> {{t('cc.cvv')}}</h4>
        </div>
        <button tabindex="0" (click)="modal.close()" class="w3-display-topright">
            <img src="../../assets/svg/close_modal.svg" alt="close" />
        </button>
    </div>
    <div *ngIf="oscCodes.includes(gd.oscConfig.oscCode)" class="modal_content__tooltip_toefl">
        <div aria-labelledby="cvv-dialog-title" class="tooltip__cvv">
            <img src="../../assets/svg/cvv_tooltip_modal.svg" alt="" />
            <h4 id="cvv-dialog-title" class="tooltip_cvv_title"> {{t('cc.cvv')}}</h4>
        </div>
        <button tabindex="0" (click)="modal.close()" class="w3-display-topright">
            <img src="../../assets/svg/close_modal.svg" alt="close" />
        </button>
    </div>
    <div class="inner-modal">
        <div class="cvvtooltip__title">
            <h5 [ngClass]="oscCodes.includes(gd?.oscConfig?.oscCode) ? 'cvvtooltip_title__headr_ert' : 'cvvtooltip_title__headr'">
                {{t('cc.cvv.tooltip.cardsinfo')}}
            </h5>
            <span [ngClass]="oscCodes.includes(gd.oscConfig.oscCode) ? 'text__tooltip_info_ert' : 'text__tooltip_info'">
                {{t('cc.cvv.tooltip.cardsdesc')}}
            </span>
        </div>
        <div class="cvvtooltip_rear_side">
            <img src="../../../assets/img/cvv_rearside.png" alt="" />
        </div>

        <div class="cvvtooltip__title">
          <h5 [ngClass]="oscCodes.includes(gd.oscConfig.oscCode) ? 'cvvtooltip_title__headr_ert' : 'cvvtooltip_title__headr'">
            {{t('cc.cvv.tooltip.express')}}
          </h5>
          <span [ngClass]="oscCodes.includes(gd.oscConfig.oscCode) ? 'text__tooltip_info_ert' : 'text__tooltip_info'"> {{t('cc.cvv.tooltip.cid')}}</span>
        </div>
        <div class="cvvtooltip_rear_side">
          <img src="../../../assets/img/cvv_card_front.png" alt="" />
      </div>
    </div>
</ng-template>
