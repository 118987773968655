import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-browser-events-modal',
  templateUrl: './browser-events-modal.component.html',
  styleUrl: './browser-events-modal.component.css'
})
export class BrowserEventsModalComponent {
  constructor(public dialogRef: MatDialogRef<BrowserEventsModalComponent>) {}

  closeDialog(shouldGoBack: boolean) {
    // Send the response to the parent component
    if (shouldGoBack) {
      this.dialogRef.close('confirmed');
    }else{
      window.history.pushState(null, '', window.location.href);
      this.dialogRef.close(shouldGoBack);
    }
  }
}
