import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {getElementBySelector, removeClass} from '../common/dom-util';
import {faLock, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {
  Consumer,
  CustomEventName,
  ICssStyleList,
  IFailureResult,
  IFields,
  IPaymentForm,
  IPaymentFormHooks,
  ISessionAuth,
  IStateConfig,
  PaymentCssClassList,
  DefaultErrors,
} from '../models/paymentjs-models';
import {GlobalAppData} from '../models/global-app-data';
import {CreditCardService} from '../services/credit-card.service';
import {Contact} from '../models/contact';
import {Address} from '../models/address';
import {CurrencyConversionResponse, Payment} from '../models/payment';
import {PaymentResponse, PaymentResponseStatus, Secure3DAuthType} from '../models/payment-response';
import {PaymentService} from '../services/payment.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {AuthenticationChallengeParams} from '../models/secure-3d-authentication';
import {Transaction} from '../models/transaction';
import {environment} from '../../environments/environment';
import {getQueryParamByName, updateQueryStringParam} from '../common/utils';
import {SharedService} from '../services/shared.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {MerchantResponseComponent} from '../merchant-response/merchant-response.component';
import {MerchantResponseData} from '../models/merchant-models';
import {ErrorMessageConstants} from '../common/error-messages.constants';
import {LoadingService} from '../services/loading.service';
import {Constants} from "../constants/constants";
import { SegmentService } from '../services/segment.service';
import { EventTypes } from '../models/enums/event-types';
import { FraudDeductionDetailsResponse } from '../models/FraudDeductionDetailsResponse';
import { Observable } from 'rxjs';
import { BlockFraudOption } from '../models/block-fraud-option';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})

export class CreditCardComponent implements OnInit, AfterViewInit, OnChanges {
  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('billingDetails') sessionAddressDetails: string;
  @ViewChild('authChallengeForm') authChallengeFormElement;
  @Input('status') paymentStatus: string;
  @Input() orderData: Transaction;
  @Input() selectPaymentType:string;

  payButton: HTMLButtonElement;
  faLock = faLock;
  faQuestionCircle = faQuestionCircle;
  show = false;
  secure3dsFormData: SafeHtml;
  authChallengeParams: AuthenticationChallengeParams;
  model: any = {};
  MAX_ALLOWED_ATTEMPTS: number = environment.MAX_PAYMENT_ALLOWED_ATTEMPTS;
  selectedCard = '';
  postalcode = '';
  dccResponse: CurrencyConversionResponse;
  isStubEnabled: boolean;
  paymentFormFields: IPaymentForm;
  creditTransactionId:string= ""
  requiredFields=[]

  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
  @Input() spcOscCode: String;

  @ViewChild('dccModal', { static: false }) private dccModal;
  showCvvForCC = true;
  @ViewChild('ccFormData') ccFormFields: NgForm;
  isCcShippingBillingSameChk = false;
  errorMessage = '';
  @Input('disabledPaymentMethodNames') disabledPaymentMethodNames = [];
  @ViewChild(MerchantResponseComponent) merchantResponseComponent;
  defaultError = ErrorMessageConstants.ERROR_DEFAULT_MESSAGE[0].errorMessage;
  sdkCardBrand: string;
  billingInformation: FormGroup;
  ccShuffleDisable:boolean = false

  //fiserv Iframe errors
  cardErrors: any = {};
  expErrors: any = {};
  cvvErrors: any = {};
  nameErrors: any = {};
  defaultErrors: DefaultErrors = { touched: true, length: 0, empty: true,};
  
  // Cc Expiration date validation 
  IsExpDateValid:boolean = true;

  constructor(public gd: GlobalAppData,
              private santizer: DomSanitizer,
              private ccService: CreditCardService,
              private paymentService: PaymentService,
              private modalService: NgbModal,
              private sharedService: SharedService,
              private _loading: LoadingService,
              private fb: FormBuilder,
              private ss: SegmentService,
              private translocoService:TranslocoService) {
  }

  ngOnInit(): void {
    this.isStubEnabled = this.gd.isStubEnabled;
    this.populateBillingAddressFor3dsRejection();
    this.initBillingAddr();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectPaymentType && !changes.selectPaymentType.firstChange) {
      if (this.selectPaymentType !== 'cc') {
        this.billingInformation.reset()
        this.resetCcFields(this.paymentFormFields)
        this.ccShuffleDisable = true;
        this.model = {}
      }
    }
  }

  initBillingAddr(){
    this.createForm();
    this.billingInformation.valueChanges.subscribe(data => {
      this.setFormData(data);
      this.setSubmitState();
    });
  }

  initialize() {
    if (!this.isStubEnabled) {
      this.payButton = getElementBySelector('[data-submit-btn]');
      if (this.orderData.modeOfReceipt && (this.orderData.modeOfReceipt === 'M' || this.orderData.modeOfReceipt === 'F')) {
        this.getCCinfo(this.getStateConfigNoCvv());
      } else {
        this.getCCinfo(this.getStateConfig());
      }
    }
  }

  // Cc Expiration date validation 
  changeStubExpDate(event) {
    let val = event.target.value;
    if(val.length >=5){
      let today:any = new Date();
      let today_mm:any = today.getMonth() + 1;
      let today_yy:any = today.getFullYear() % 100;

      if(today_mm < 10) {
        today_mm = '0' + today_mm;
      }
      let mm = val.substring(0, 2);
      let yy = val.substring(3);
      if (mm <=12 && (yy > today_yy || (yy == today_yy && mm >= today_mm))) {
        this.IsExpDateValid = true;
      } else {
        this.IsExpDateValid = false;
      }
    }
  }

  private getCCinfo(config: IStateConfig) {
    this.createAsync(config, { preFlowHook: this.requestSession })
      .then((res: any) => this.isErrorMessage(res, 'Create Fields'))
      .then((paymentForm: IPaymentForm) => {
        this.paymentFormFields = paymentForm;
        this.enableForm();
        this.submitCcInfo(paymentForm);
        this.resetCcFields(paymentForm);
        this.payButton.disabled = paymentForm.isValid()?false:true;
        this.iframeFieldOnBlur(paymentForm);
        this.iframeFieldOnChange(paymentForm);
        this.onCardType(paymentForm);
      }).catch((reason) => {
      });
  }

  private populateBillingAddressFor3dsRejection(): void {
    if ((this.paymentStatus && this.paymentStatus !== 'approved') && this.sessionAddressDetails) {
      this.mapToCurrentLangauge();
      this.errorMessage = this.getErrorMessage({ status: '3DS' });
      this.generateNewTransactionToRetry();
    }
  }

  ngAfterViewInit() {
    this.initialize();
  }

  openLearnMore(dsmodal) {
    this.modalService.open(dsmodal, { ariaLabelledBy: '3DS_Disclaimer', backdrop: 'static' }).result.then(() => { });
  }

  opendcc(dccModal) {
    this.modalService.open(dccModal, { backdrop:  'static', keyboard:false}).result.then((result) => {
    });
  }

  exitdcc(isDccEnabled: boolean): void {
    if (isDccEnabled) {
      this.ss.track(EventTypes.dccSubmitted, this.sdkCardBrand, this.dccResponse);
    }
    this.dccResponse = null;
    this.createPayment(isDccEnabled);
  }

  requestSession = (cb: Consumer<ISessionAuth>): void => {
    this.ccService.getCreditCardSessionDetails(this.gd.transId, this.selectedCard, this.sdkCardBrand)
      .then((res: ISessionAuth) => this.isErrorMessage(res, 'Authorize Client'))
      .then(cb)
      .catch((err) => {
        this.removeSubmitState();
        this._loading.setLoading(false, 'internal');
        if(err.status === 500 || err.pgmResponseMessage === "Error integrating with data service"){
          this.merchantResponseComponent.backToOrder('ccSwitchedPayment','',{})
        }
        throw new Error('Session Authorization Error');
      });
  }

  enablePaymentFields = () => {
    const ccFields = window.document.getElementsByClassName('payment-fields');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < ccFields.length; i++) {
      removeClass(ccFields[i], 'disabled');
    }
  }

  enableForm = () => {
    setTimeout(() => {
      if (this.payButton) {
        this.enablePaymentFields();
      }
    }, 100);
  }

  removeSubmitState(): void {
    if (this.payButton) {
      this.payButton.disabled = false;
      removeClass(this.payButton, 'disabled-bkg');
    }
  }

  setSubmitState(): void {
    if (!this.isStubEnabled) {
      this.enabledPayButton(this.paymentFormFields);
    }else{
      this.checkValidationForVisa();
    }
  }

  enabledPayButton(paymentForm: IPaymentForm): void {
    const isCard = paymentForm.state.fields.card.validity;
    const isIHOP = this.orderData.modeOfReceipt === 'M' || this.orderData.modeOfReceipt === 'F' ;
    let isCvv = isIHOP ? null: paymentForm.state.fields.cvv.validity;
    const isExp = paymentForm.state.fields.exp.validity;
    const isName = paymentForm.state.fields.name.validity;
    const streeaddr = this.model.streetAddress1;
    const city = this.model.city;
    const country = this.model.country;
    const region = this.model.country=='CA' ? this.model.province : this.model.region;
    const postalCodeValid = this.model.postalcode == null ? 0 : this.model.postalcode.length;
    this.ccFormValid(isCard, isCvv,isExp, isName, country, streeaddr, city, region,postalCodeValid);
  }

  checkValidationForVisa(){
    if (this.model.cardType == '001' &&  this.payButton) {
      if (!this.model.phone || !this.model.phone.trim() || !this.model.email || !this.model.email.trim()) {
        this.payButton.disabled = true;
      }else{
        this.payButton.disabled = false;
      }
    }
  }

  isValidForVisa(){
    if (this.model.cardType == '001' ) {
      if (!this.model.phone || !this.model.phone.trim() || !this.model.email || !this.model.email.trim()) {
        return false;
      }else{
        return this.model.valid;
      }
    } else{
      return this.model.valid;
    }
  }

  private ccFormValid(isCard: any, isCvv: any, isExp: any, isName: any, country: any, streeaddr: any, city: any, region: any, postalCodeValid: any) {
    if (!isCard.valid || (!isCvv?.valid && isCvv !== null) || !isExp.valid || isName.empty || country == null || streeaddr == null || city == null) {
      this.payButton.disabled = true;
    } else if (country == 'US' || country == 'CA') {
      if (!(!isCard.valid || (!isCvv?.valid && isCvv !== null) || !isExp.valid || isName.empty || streeaddr == null || city == null || streeaddr == "" || city == "")) {
          if (region == null || region == "") {
            this.payButton.disabled = true;
          } else if ((country == 'US' && (postalCodeValid > 9 || postalCodeValid < 5)) || (country == 'CA' && (postalCodeValid > 9 || postalCodeValid < 6))) {
            this.payButton.disabled = true;
          } else if(postalCodeValid > 10) {
          this.payButton.disabled = true;
        }else {
          this.payButton.disabled = false;
          this.checkValidationForVisa();
        }
      } else {
        this.payButton.disabled = false;
        this.checkValidationForVisa();
      }
    } else {
      this.payButton.disabled = false;
      this.checkValidationForVisa();
    }
  }

  getRequiredFields(){
    this.requiredFields=[];
    if(this.model.cardType=='001'){
      this.requiredFields=['phoneNumber','email'];
    }
    return this.requiredFields;
  }

  isErrorMessage(res: any, title: string): any {
    if (res && res.error) {
      this.removeSubmitState();
      console.error(`Error message ${res.error}`);
      throw new Error(`${title} Error`);
    } else {
      if (title === 'Authorize Client' && this.gd.oscConfig.fraudDetectEnabled) {
        this.ccService.getFraudScore(this.gd.transId);
      }
      return res;
    }
  }

  resetCcFields(paymentForm: IPaymentForm): void {
    paymentForm.reset(() => console.log('paymentForm.reset'));
    this.payButton.disabled = true;
  }

  iframeFieldOnBlur(paymentForm: IPaymentForm): void {
    paymentForm.on(CustomEventName.BLUR, (res: any) => {
      this.payButton.disabled = paymentForm.isValid()?false:true;
      if(!this.cardErrors?.field && res.field === "card"){
        this.cardErrors = this.defaultErrors;
      } else if (!this.expErrors?.field && res.field === "exp"){
        this.expErrors = this.defaultErrors;
      } else if (!this.cvvErrors?.touched && !this.cvvErrors?.field && res.field === "cvv"){
        this.cvvErrors = this.defaultErrors;
      } else if (!this.nameErrors?.field && res.field === "name"){
        this.nameErrors = this.defaultErrors;
      }
    });
  }

  // Validation for fiserv credit form fields
  iframeFieldOnChange(paymentForm: IPaymentForm): void {
    paymentForm.on(CustomEventName.CHANGE, (data:any) => {
      if(data.touched && data.field === "card"){
        this.cardErrors = data;
      } else if (data.touched && data.field === "exp"){
        this.expErrors = data;
      } else if (data.touched && data.field === "cvv"){
        this.cvvErrors = data;
      } else if (data.touched && data.field === "name"){
        this.nameErrors = data;
      }
    });
  }

  handleDccError(error: any) {
    this.mapToCurrentLangauge();
    const dccErrorResponse = error?.error;
    if(dccErrorResponse?.decision === 'ERROR' && dccErrorResponse?.pgmResponseCode === '150'
        && dccErrorResponse?.error.startsWith('Error encountered while processing card details')) {
          this.handleCcError(dccErrorResponse.pgmResponseMessage);
    } else {
      this.createPayment(false);
    }
  }

  submitCcInfo(paymentForm: IPaymentForm): void {
    const onSuccess = () => {
      this.ss.track(EventTypes.ccTokenSuccess, this.sdkCardBrand);
      if(this.creditTransactionId !== this.gd.transId) {
        this.getDccDetails();
        this.resetCcFields(paymentForm);
      }
      this.creditTransactionId = this.gd.transId;
    };

    const onError = (error: Error) => {
      this.ss.track(EventTypes.ccTokenError, this.sdkCardBrand);
      console.error(`Error submitting cc details ${JSON.stringify(error)}`);
      this.removeSubmitState();
      this.resetCcFields(paymentForm);
      this.handleCcError(EventTypes.ccTokenError);
    };

    getElementBySelector('#ccForm').addEventListener('submit', (e: Event) => {
      e.preventDefault();
      this._loading.setLoading(true, 'internal');
      this.gd.paymentType = 'cc';
      this.ss.track(EventTypes.ccSubmitted, this.sdkCardBrand);
      this.setSubmitState();
      paymentForm.onSubmit(onSuccess, onError);
    });
  }

  getDccDetails(): void {
    sessionStorage.setItem('page', this.gd.attemptCount.toLocaleString());
    if (this.gd && this.gd.oscConfig && this.gd.oscConfig.dccEnabled && 'subscription' !== this.gd?.sourceType.toLowerCase()) {
      this.ccService.getDccDetails(this.gd.transId)
        .subscribe({next:(result: CurrencyConversionResponse) => {
          this._loading.setLoading(false, 'internal');
          if (!result.dccOffered) {
            this.ss.track(EventTypes.dccNotOffered, this.sdkCardBrand);
            this.createPayment(false);
          } else {
            this.ss.track(EventTypes.dccOffered, this.sdkCardBrand, result);
            this.dccResponse = result;
            this.opendcc(this.dccModal);
          }
        },error: (error: any) => {
          this.handleDccError(error);
        }});
    } else {
      this.createPayment(false);
    }
  }

  isFraudTransaction(isDccEnabled:boolean,urlType:string):PaymentResponse{
    const paymentResponse:PaymentResponse={} as PaymentResponse;
    let obs:Observable<FraudDeductionDetailsResponse> =this.ccService.getFraudCheck( this.gd.transId,this.getPaymentRequest(isDccEnabled));
    obs.subscribe({next:(resp:FraudDeductionDetailsResponse)=>{
      if(resp.isFraud){
        paymentResponse.attemptCounter=this.gd.orderTransaction.order.attemptCounter;
        paymentResponse.status="DECLINED";
        paymentResponse.fraudDetectionResponse='decline';
        this.processPaymentResponse(paymentResponse);
      }else{
        this.makePayment(isDccEnabled,urlType);
      }
    },
    error:(error:any)=>{
      this.makePayment(isDccEnabled,urlType);
    }});
    return paymentResponse;
  }

  isFraudEnabled():boolean{
    if(this.gd.oscConfig?.fraudDetectEnabled){
      return !this.gd.oscConfig.blockFraudDtctns.some((element:BlockFraudOption) => {
        const country:string=this.model.country;
        if(element.country?.countryIsoCode?.localeCompare(country) === 0){
          return true;
        }
      });
    }else{
      return false;
    }
  }

  createPayment(isDccEnabled: boolean): void {
    let urlType = this.gd?.sourceType.toLowerCase() === 'subscription' ? '/subscription' : '/cc';
    if(this.gd?.sourceType.toLowerCase() === 'refund') {
      urlType = '/ccVTRefund';
    }
    this._loading.setLoading(true, 'internal');
    if(this.isFraudEnabled()){
      this.isFraudTransaction(isDccEnabled,urlType);
    }else{
      this.makePayment(isDccEnabled,urlType);
    }
    
  }

  makePayment(isDccEnabled,urlType){
    this.paymentService.createPayment(this.getPaymentRequest(isDccEnabled), this.gd.transId, urlType)
      .subscribe({next:(result: PaymentResponse) => {
        if (result.status == '500') {
          this.ss.track(EventTypes.ccSubmitError, this.sdkCardBrand);
          this.handleError(result);
        } else {
          this.ss.track(EventTypes.ccSubmitSuccess, this.sdkCardBrand);
          this.processPaymentResponse(result);
        }
      },
      error: (error: any) => {
        this.ss.track(EventTypes.ccSubmitError, this.sdkCardBrand);
        this.handleError(error);
      }
    });
  }

  handleError(error: any) {
    this.mapToCurrentLangauge();
    if (error && error.status === 424) {
      this.handleCcDecline({ attemptCounter: this.gd.attemptCount });
    } else {
      this.errorMessage = (error?.status === 500 || error?.status === 400) ? this.defaultError : this.getErrorMessage(error);
      this.handleCcError(this.errorMessage);
    }
  }

  getStateConfig(): IStateConfig {
    const { classes, styles }: {
      classes: PaymentCssClassList;
      styles: ICssStyleList;
    } = this.config();

    const fields: IFields = {
      card: {
        selector: '[data-cc-card]',
        placeholder: '',
      },
      cvv: {
        selector: '[data-cc-cvv]',
        placeholder: '',
      },
      exp: {
        selector: '[data-cc-exp]',
        placeholder: '',
      },
      name: {
        selector: '[data-cc-name]',
      }
    };
    return { classes, fields, styles };
  }

  getStateConfigNoCvv(): IStateConfig {
    const { classes, styles }: {
      classes: PaymentCssClassList;
      styles: ICssStyleList;
    } = this.config();

    const fields: IFields = {
      card: {
        selector: '[data-cc-card]',
        placeholder: '',
      },
      exp: {
        selector: '[data-cc-exp]',
        placeholder: '',
      },
      name: {
        selector: '[data-cc-name]',
      }
    };
    return { classes, fields, styles };
  }

  private config() {
    const styles: ICssStyleList = {
      input: {
        'font-size': '14px',
        color: 'black',
        'font-family': 'Open Sans',
        background: 'black',
      },
      '.card': {
        'font-family': 'Open Sans',
      },
      ':focus': {
        color: 'black',
      },
      '@media screen and (max-width: 700px)': {
        input: {
          'font-size': '14px',
        },
        'input:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 50px white inset',
        },
        'input:focus:-webkit-autofill': {
          '-webkit-text-fill-color': 'black',
        },
      }
    };

    const classes: PaymentCssClassList = {
      empty: 'empty',
      focus: 'focus',
      invalid: 'invalid',
      valid: 'valid',
    };
    return { classes, styles };
  }

  createAsync(config: IStateConfig, hooks: IPaymentFormHooks): Promise<IPaymentForm> {
    const logger = (level: string, msg: string) => console.log('msg is .................',msg);
    return new Promise<IPaymentForm>((resolve: Consumer<IPaymentForm>, reject: Consumer<IFailureResult>) => {
      try {
        (window as any).firstdata.createPaymentForm(config, hooks, resolve, logger);
      } catch (error) {
        reject({ error: true, reason: error.message });
      }
    });
  }

  private getPaymentRequest(isDccOpted: boolean): Payment {
    const request = {} as Payment;
    // prepare address and assign to order
    
    const billingAdress = {} as Address;
    billingAdress.firstName = this.orderData.shippingAddress.firstName;
    billingAdress.lastName = this.orderData.shippingAddress.lastName;
    billingAdress.addressLine1 = this.model.streetAddress1;
    billingAdress.addressLine2 = this.model.streetAddress2;
    billingAdress.city = this.model.city;
    billingAdress.company = this.model.company ? this.model.company : null;
    billingAdress.country = this.model.country;
    billingAdress.postalCode = this.model.postalcode;

    if ('CA' === billingAdress.country) {
      billingAdress.region = this.model.province;
    } else {
      billingAdress.region = this.model.region;
    }
    // assign both billing and shipping address here
    request.billingAddress = billingAdress;

    // prepare contact and assign to order
    const bContact = {} as Contact;
    if (this.model.email) {
      bContact.email = this.model.email;
    }
    if (this.model.phone) {
      bContact.phoneNumber = this.model.phone;
    }
    // assign both shipping and billing contact here
    if (bContact) {
      request.billingContact = bContact;
    }
    request.dccOpted = isDccOpted;
    request.cardType = this.model.cardType;
    request.screenHeight = window.screen.height.toString();
    request.screenWidth = window.screen.width.toString();
    this.gd.address = JSON.stringify(this.model);
    return request;

    
  }
  
  // this will take the user throught the security challenges.
  private verifyPaymentDetails(response: PaymentResponse): void {
    if (response.secure3DAuthType === Secure3DAuthType.form_3ds) {
      this.ss.track(EventTypes.threedsForm, this.sdkCardBrand);
      this.secure3dsFormData = this.santizer.bypassSecurityTrustHtml(response.authenticationResponse.methodForm);
      this.get3dsflowStatus();
    } else if (response.secure3DAuthType === Secure3DAuthType.challenge) {
      this.ss.track(EventTypes.challengeForm, this.sdkCardBrand);
      sessionStorage.setItem('gd', Buffer.from(JSON.stringify(this.gd)).toString('base64'));
      sessionStorage.setItem('sessionid', getQueryParamByName('jSession'));
      this.redirectToAcsServer(response.authChallengeParams);
    }
  }

  private get3dsflowStatus(): void {
    if (this.gd && this.gd?.oscConfig && this.gd?.oscConfig.threeDSEnabled) {
      this.paymentService.retrieve3dsDetails(this.gd.transId)
        .subscribe({next:(result: PaymentResponse) => {
          this.processPaymentResponse(result);
        }, error:(error: any) => {
          console.log("error 3ds flow status..............",error)
          this.handleCcError(error?.error.pgmResponseMessage);
        }});
    }
  }

  private redirectToAcsServer(challengeParams: AuthenticationChallengeParams): void {
    this.authChallengeParams = challengeParams;
    setTimeout(() => {
      this.authChallengeFormElement.nativeElement.submit();
    }, 100);
  }

  // this will get the response and based on the status performs the next steps to verify the payment.
  private processPaymentResponse(response: PaymentResponse): void {
    const paymentStatus = PaymentResponseStatus[response.status];
    switch (paymentStatus) {
      case 0:
        this.redirectToMerchantURL(response);
        break;
      case 1:
        this.verifyPaymentDetails(response);
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        this.handleCcDecline(response);
        break;
    }
  }

  handleCcDecline(response: PaymentResponse): void {
    this.mapToCurrentLangauge();
    this.paymentStatus = (response?.fraudDetectionResponse && response?.fraudDetectionResponse !== 'approve') ? 'fraudErrorMsg' : 'declined';
    this.errorMessage = this.getErrorMessage(response);
    this.removeSubmitState();
    this.handleCcError(this.errorMessage, this.paymentStatus, response);
  }

  getErrorMessage(data: any) {
    let category = '';
    for (const index of ErrorMessageConstants.ERROR_CATEGORY) {
      if (data?.pgmResponseCode === index.AUTH_RESP_CODE) {
        category = index.CATEGORY;
      }
    }
    for (const index of ErrorMessageConstants.ERROR_MESSAGES_CREDITCARD) {
      if (category.toLowerCase() === index.errorType.toLowerCase()) {// category error
        return index.errorMessage;
      } else if (data?.fraudDetectionResponse) {// fraud
        if (data.fraudDetectionResponse.toLowerCase() === 'decline' && index.errorType.toLowerCase() === 'fraud') {
          return index.errorMessage;
        }
      } else if (data?.status && !data?.error) {// declined and status=reject
        if (data.status.toLowerCase() === index.errorType.toLowerCase()) {
          return index.errorMessage;
        }
      } else if (data?.error && index.errorType.toLowerCase() === 'error') { // error occurred
        return index.errorMessage;
      }
    }
    return ErrorMessageConstants.ERROR_DEFAULT_MESSAGE[0].errorMessage;
  }

  submitStubForm(formData: NgForm): void {
    this.gd.paymentType = 'cc';
    const ccFormData = formData.value;
    const ccExpiryDate = ccFormData.stubExpDate.trim();
    const reqBody = {
      name: ccFormData.stubCcName,
      cardNumber: ccFormData.stubCardNumber,
      cvv: ccFormData.stubCvv,
      expiryDate: ccExpiryDate,
      transactionId: this.gd.transId
    };
    this._loading.setLoading(true, 'internal');
    this.ccService.postCcDetailsToStub(this.gd.transId, reqBody).subscribe({next:(res: boolean) => {
      this.getDccDetails();
    },error: (error: any) => {
      this._loading.setLoading(false, 'internal');
    }});
  }

  redirectToMerchantURL(response: PaymentResponse): void {
    let merchantSuccessURL;
    if (this.orderData && this.orderData.order) {
      merchantSuccessURL = this.orderData.order.merchantSuccessURL;
    }
    if (!merchantSuccessURL) {
      merchantSuccessURL = this.gd.oscConfig.successUrl;
    }
    this.redirectToMerchant(merchantSuccessURL, response);
  }

  redirectToMerchantRejectURL(response: PaymentResponse): void {
    let merchantRejectURL = this.orderData.order.merchantRejectURL;
    if (!merchantRejectURL) {
      merchantRejectURL = this.gd.oscConfig.rejectUrl;
    }
    this.redirectToMerchant(merchantRejectURL, response);
  }

  redirectToMerchantErorURL(response: PaymentResponse): void {
    let merchantErrorURL = this.orderData.order.merchantErrorURL;
    if (!merchantErrorURL) {
      merchantErrorURL = this.gd.oscConfig.errorUrl;
    }
    this.redirectToMerchant(merchantErrorURL, response);
  }

  redirectToMerchant(url: string, response: PaymentResponse): void {
    sessionStorage.clear()
    window.sessionStorage.clear()
    const paymentActionData = {
      url,
      orderTransaction: this.orderData,
      paymentResponse: response,
    } as MerchantResponseData;
    this.merchantResponseComponent.sendResponseToMerchant('CC', paymentActionData);
  }

  isShowCvv(): boolean {
    if (this.orderData && this.gd && this.gd.oscConfig && this.model.cardType) {
      if (this.orderData.modeOfReceipt === 'M' || this.orderData.modeOfReceipt === 'F') {
        return false;
      }
    }
    return true;
  }

  onCardTypeChange(value): void {
    // reset cc fields except cardType
    this.selectedCard = this.model.cardType;
    for (const ctrl in this.ccFormFields.controls) {
      if (this.ccFormFields.controls.hasOwnProperty(ctrl) && ctrl !== 'cardType') {
        if (this.ccFormFields.controls[ctrl].value) {
          this.ccFormFields.controls[ctrl].reset();
        }
      }
    }
    if (!this.isStubEnabled) {
      this.resetCcFields(this.paymentFormFields);//clear the CC fields, whenever change the card
      
      // Clear Field errors
      this.cardErrors = {};this.expErrors= {};
      this.cvvErrors = {};this.nameErrors = {};
    }
    this.billingInformation.reset();
    this.paymentStatus = null; // clear the error message div on UI
    this.isCcShippingBillingSameChk = false;
    if (this.gd && this.gd.oscConfig) {
      const oscConfig = this.gd.oscConfig;
      switch (value) {
        case '001':
          this.showCvvForCC = oscConfig.visaCSCRequired;
          break;
        case '002':
          this.showCvvForCC = oscConfig.mastercardCSCRequired;
          break;
        case '003':
          this.showCvvForCC = oscConfig.amexCSCRequired;
          break;
        case '007':
          this.showCvvForCC = oscConfig.jcbCSCRequired;
          break;
        case '004':
          this.showCvvForCC = oscConfig.discoveryCSCRequired;
          break;
        case '006':
          this.showCvvForCC = oscConfig.unionPayCSCRequired;
          break;
        case '005':
          this.showCvvForCC = oscConfig.dinersClubCSCRequired;
          break;
        case '008':
          this.showCvvForCC = oscConfig.rupayGlobalCSCRequired;
          break;
        default:
          this.showCvvForCC = false;
          break;
      }
    }
  }

  showCreditcardType(val: string): boolean {
    return this.disabledPaymentMethodNames.indexOf(val) === -1;
  }

  onCardType(paymentForm: IPaymentForm) {
    paymentForm.on(CustomEventName.CARD_TYPE, (res: any) => {
      console.log(`${res.brandNiceType}`);
      this.sdkCardBrand = res.brandNiceType;
    });
  };

  setFormData(formData) {
    this.model.streetAddress1 = formData.addressDetails.streetAddress1;
    this.model.streetAddress2 = formData.addressDetails.streetAddress2;
    this.model.city = formData.addressDetails.city;
    this.model.country = formData.addressDetails.country;
    this.model.region = formData.addressDetails.region;
    this.model.province = formData.addressDetails.province||formData.addressDetails.region;
    this.model.postalcode = formData.addressDetails.zipcode;
    this.model.company = formData.addressDetails.company;
    this.model.phone = formData.addressDetails.phoneNumber;
    this.model.email = formData.addressDetails.email;
    this.model.sameAsShippingContactAddress = formData.addressDetails.sameAsShippingContactAddress;
    this.model.valid=this.billingInformation.valid;
    this.payButton.disabled = false;
    this.setSubmitState();
  }

  createForm() {
    this.billingInformation = this.fb.group({
      addressDetails: this.fb.group({
        streetAddress1: new FormControl(null, Validators.required),
        streetAddress2: new FormControl(),
        city: new FormControl(null, Validators.required),
        country: new FormControl('', Validators.required),
        region: new FormControl({value: null, disabled: true}),
        zipcode: new FormControl({value: null, disabled: true}),
        company: new FormControl(null),
        phoneNumber: new FormControl(
          '',
          Validators.pattern(Constants.PHONENUMBER_VALIDATOR_PATTERN)
        ),
        email: new FormControl(
          '',
          Validators.pattern(Constants.EMAIL_VALIDATOR_PATTERN)
        ),
        sameAsShippingContactAddress: new FormControl(),
      }),
    });
  }

  generateNewTransactionToRetry(): void {
    this.sharedService.getNewTransactionId(this.gd.transId)
      .then((res: string) => {
        this.gd.transId = res;
        this.gd.attemptCount++;
        updateQueryStringParam('t', res);
      }).catch((error) => {
        if (error && error.status && error.error) {
          console.debug(`error cc new txn attempt ${error?.error}`);
        }
      }).finally(() => {
        this._loading.setLoading(false, 'internal');
      });
  }

  mapToCurrentLangauge(){
    this.translocoService.setActiveLang(this.gd?.orderTransaction?.order?.displayLanguage);
  }

  handleCcError(errorMessage: string, paymentStatus?: string, response?: PaymentResponse) {
    this.mapToCurrentLangauge();
    this.paymentStatus = paymentStatus || 'paymentError';
    this.errorMessage = errorMessage;
    this.ccFormFields.reset();
    this.billingInformation.reset();
    this.isCcShippingBillingSameChk = false;
    if (this.gd.attemptCount < this.gd.oscConfig.maxRejectAtmptCount) {
      this.generateNewTransactionToRetry();
    } else {
      sessionStorage.clear();
      this.redirectToMerchantRejectURL(response || {});
    }
    this._loading.setLoading(false, 'internal');
  }

}
