<div class="container-xl mt-4">
  <div class="card col-12 light-blue mt-lg-5">
    <div class="card-body mt-lg-5 ms-lg-5" *transloco="let t">
      <app-merchant-response></app-merchant-response>
      <h3 *ngIf="validTransaction">{{t('transaction.progress.text')}}</h3>
      <p *ngIf="!validTransaction">
        {{t('transaction.progress.error')}}
      </p>
      <div *ngIf="validTransaction" class="mt-lg-5 ms-lg-4 mt-4 ms-2">
        <p>
          {{t('transaction.progress.contacting.bank')}}
        </p>
        <p>
          {{t('transaction.progress.check.later')}}
        </p>
        <b [class]="hideTimer ? 'text-danger fs-5' : ''"><span *ngIf="hideTimer">{{t('transaction.progress.longerthen.expected')}}</span>
          {{t('transaction.progress.donot.resubmit')}}</b>
      </div>
      <div *ngIf="!hideTimer && validTransaction" id="countdown">
        <svg>
          <circle r="72" cx="80" cy="80" stroke="#003082"></circle>
        </svg>
        <div id="countdown-number"></div>
      </div>
    </div>
  </div>
</div>