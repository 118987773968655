import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Payment } from '../models/payment';
import { PaymentResponse } from '../models/payment-response';
import { environment } from '../../environments/environment';
import { tap, share, finalize } from 'rxjs/operators';

import {BrowserDetails} from '../models/browser-details';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  hostUrl = environment.PAYMENT_UI;

  constructor(private http: HttpClient) {
  }

  private paymentCachedObservable: Observable<PaymentResponse>;
  private acsCachedResponse: string;
  private acsCachedObservable: Observable<string>;

  /**
   * This method makes call to make payment.
   * if the response already exists returns that, if not it will return cached observable if exists.
   * else it will make api call to make the payment
   * 
   * @param orderRequest Payment request details 
   * @param transactionId transaction id to make payment for
   * @param paymentType payment type either cc or telecheck or vt.
   * @returns PaymentResponse as Observable.
   */
  createPayment(orderRequest: Payment, transactionId: string, paymentType: string): Observable<PaymentResponse> {
    let observable: Observable<PaymentResponse>;
    const paymentUrl = this.hostUrl + '/epms/payment/' + transactionId + paymentType;
    this.paymentCachedObservable = this.http.post<PaymentResponse>(paymentUrl, orderRequest, this.httpOptions).pipe(
        tap( res => res), 
        share(), finalize(() => this.paymentCachedObservable = null));
    observable = this.paymentCachedObservable;
    return observable;
  }

  updateCustomerBrowserDetails(browserDetails: BrowserDetails): Observable<string> {
    const url = environment.PAYMENT_UI + '/epms/customerBrowserDetails';
    return this.http.post<string>(url, browserDetails, this.httpOptions);
  }

  retrieve3dsDetails(transactionId: string): Observable<PaymentResponse> {
    return this.http.patch<PaymentResponse>(this.hostUrl + '/epms/3ds/' + transactionId, this.httpOptions);
  }

  /**
   * This method makes call to get 3ds 1.0 form.
   * if the response already exists returns that, if not it will return cached observable if exists.
   * else it will make api call to get 3ds 1.0 form.
   * 
   * @param formData form data to submit
   * @param postUrl url to post formData too
   * @returns string
   */
  getAcsForm(formData: any, postUrl: string): Observable<string> {
    let observable: Observable<string>;
    if (this.acsCachedResponse) {
      console.debug(`inside the Acache`);
      observable = of(this.acsCachedResponse);
    } else if (this.acsCachedObservable) {
      console.debug(`inside the Acache res`);
      observable = this.acsCachedObservable;
    } else {
      console.debug(`inside the Acache res1`);
      this.acsCachedObservable = this.http.post(postUrl, formData, { responseType: 'text' }).pipe(
          tap( res => this.acsCachedResponse = res), 
          share(), finalize(() => this.acsCachedObservable = null));
      observable = this.acsCachedObservable;
    }
    return observable;
  }
}
