import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { Transaction } from '../models/transaction';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Constants } from '../constants/constants';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css'],
})
export class CheckoutComponent{

  @Input() orderData: Transaction;
  @ViewChild(MerchantResponseComponent) public merchantResponseComponent;
  @ViewChild('expandBtn') expandBtn: ElementRef;
  @ViewChild('collapseBtn') collapseBtn: ElementRef;
  faDown = faArrowDown;
  faUp = faArrowUp;
  expanddetails: boolean = false;
  collapsable_line__items_condition: string = ' collapsable_line__items';
  @Input() spcOscCode: String;
  tabIndex:number = 0;

  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  constructor(private cdRef: ChangeDetectorRef) {
  }

  modifyYourOrder() {
    this.merchantResponseComponent.backToOrder('backToOrder', '', {});
  }

  expandDetails(val: boolean) {
    this.expanddetails = val;
    this.collapsable_line__items_condition = '';
    this.cdRef.detectChanges();
    setTimeout(() => {
      var staticContent = document.getElementById('panel-1');
      staticContent.focus();
    }, 100);
  }

  collapseDetails(val: boolean) {
    this.collapsable_line__items_condition += ' collapsable_line__items';
    this.expanddetails = val;

    // After collapsing, set focus back to the expand button
    setTimeout(() => {
      if (this.expandBtn) {
        this.expandBtn.nativeElement.focus();
      }
    }, 100);
  }

  expandDetailsOnKey(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.expandDetails(true);
    }
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        this.tabIndex = -1;
        console.log('[ tabIndex shift key  ]', this.tabIndex)
      } else {
        this.tabIndex = 0;
      }
    }
  }

  collapseDetailsOnKey(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.collapseDetails(false);
    }
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        this.tabIndex = -1;
        console.log('[ tabIndex shift key  ]', this.tabIndex)
      } else {
        this.tabIndex = 0;
      }
    }
  }


  splitDesc(desc){
    if(desc){
      return desc.split("||");
    }
  }
}