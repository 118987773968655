<div class="accepted_logos">
    <div class="accepted_title">
        <h3 class="accepted_title__header font_family__standard">Accepted Payment Types</h3>
    </div>
    <div class="accpt_pymnt__types">
        <ul class="align__payment_types_acptnc no-bullets" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
            <li *ngIf="gd?.oscConfig?.visaEnabled && showCreditcardType('Visa')" tabindex="0" aria-label="Visa Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsvisa-copy.png" alt="Visa Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.masterCardEnabled && showCreditcardType('MasterCard')" tabindex="0" aria-label="MasterCard Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsmastercard-copy.png" alt="MasterCard Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.amexEnabled && showCreditcardType('Amex')" tabindex="0" aria-label="American Express Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsamex-copy.png" alt="American Express Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.discoverEnabled && showCreditcardType('Discover')" tabindex="0" aria-label="Discover Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsdiscover-copy.png" alt="Discover Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.jcbEnabled && showCreditcardType('JCB')" tabindex="0" aria-label="JCB Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsjcb-copy.png" alt="JCB Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.unionPayEnabled && showCreditcardType('UnionPay')" tabindex="0" aria-label="UnionPay Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsunionpay-copy.png" alt="UnionPay Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.dinersClubEnabled && showCreditcardType('Diners Club')" tabindex="0" aria-label="Diners Club Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsdinersclub-copy.png" alt="Diners Club Payment" />
            </li>
            <li *ngIf="gd?.oscConfig?.rupayGlobalEnabled && showCreditcardType('RuPay Global')" tabindex="0" aria-label="RuPay Payment Logo">
                <img class="pymnt_cc_amex_logo" src="../../assets/img/epms-payment-cardsrupay-copy.png" alt="RuPay Payment" />
            </li>
            <li *ngIf="paypalEnabled" tabindex="0" aria-label="PayPal Payment Logo">
                <!-- PayPal Logo -->
                <img class="pymnt_cc_amex_logo paypal_logo" src="../../assets/img/PP_Acceptance_Marks_for_LogoCenter_150x94.png" alt="PayPal Payment" />
                <!-- PayPal Logo -->
            </li>
        </ul>

        <!-- Alipay section -->
        <ul class="no-bullets" *ngIf="alipayEnabled">
            <li class="alipay-con" aria-label="Alipay Payment Logos" role="listitem">
                <img class="pymnt_cc_amex_logo__alipayplus" src="../../assets/img/alipay/Alipayplus.png" alt="Alipay+" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Alipay.png" alt="Alipay" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Akulaku.png" alt="Akulaku" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Alipay_HK.png" alt="Alipay Hong Kong" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Dana.png" alt="Dana" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/G-cash.png" alt="G-Cash" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Kakao_Pay.png" alt="Kakao Pay" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Kredivo.png" alt="Kredivo" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Rabbit_Pay.png" alt="Rabbit Pay" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/Touch_N_Go.png" alt="Touch N Go" tabindex="0" />
                <img class="pymnt_cc_amex_logo__alipay_akulaku" src="../../assets/img/alipay/True_Money.png" alt="True Money" tabindex="0" />
            </li>
        </ul>        
    </div>
</div>
