import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalAppData } from '../models/global-app-data';
import { faCreditCard, faLandmark } from '@fortawesome/free-solid-svg-icons';
import { Transaction } from '../models/transaction';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { OscConfig } from '../models/osc-config';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { AlipayLearnMoreDialogComponent } from '../alipay-learn-more/alipay-learn-more.component';
import { Constants } from '../constants/constants';
import { SegmentService } from '../services/segment.service';
import { EventTypes } from '../models/enums/event-types';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  @Input() paymentType: string;
  @Input() contact: string;
  @Input() paymentStatus: string;
  @Input() alipayStatus : string
  @Input() orderData: Transaction;
  selectPymntType: string = '';
  loading = true;
  billingDetails: string = null;
  status: string = null;
  showBankAccount = false;
  faCreditCard = faCreditCard;
  faLandmark = faLandmark;
  faPaypal = faPaypal;
  showPaypal = false;
  activeBtn = false;
  activePP = false;
  activeBA = false;
  activeAP = false;
  @ViewChild(MerchantResponseComponent) public merchantResponseComponent;
  paypalEnabled = false;
  alipayEnabled = false;
  isStubEnabled: boolean;
  @Input() spcOscCode: String;
  creditCardComponentLoad:boolean = false;

  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  constructor(public gd: GlobalAppData,
              private dialog: MatDialog, 
              private ss: SegmentService) { }

  ngOnInit(): void {
    this.isStubEnabled = this.gd.isStubEnabled;
    this.handleAcsResponse();
  }

  loadCreditCardComponentConditionally(){
    this.creditCardComponentLoad = true
  }

  clearPaymemtStatus(paymenttype){
    if(paymenttype == 'cc'){
      this.ss.track(EventTypes.ccSelected, 'cc');
      document.documentElement.style.setProperty('--cc_load_click','block');
      this.alipayStatus = undefined;;
    }
    if(paymenttype == 'pp'){
      document.documentElement.style.setProperty('--cc_load_click','none');
      this.status = undefined;;
      this.alipayStatus = undefined;
      this.ss.track(EventTypes.ppSelected, 'paypal');;
    }
    if(paymenttype == 'alp'){
      document.documentElement.style.setProperty('--cc_load_click','none');
      this.status = undefined;;
      this.ss.track(EventTypes.alipySelected, 'alipay');
    }
  }

  private handleAcsResponse(): void {
    if (!this.paymentStatus && (this.gd.attemptCount >= environment.MAX_PAYMENT_ALLOWED_ATTEMPTS)) {
      setTimeout(() => {
        this.merchantResponseComponent.paymentBackAction('paymentBack', '', {});
      }, 300);
    }
    if (this.paymentStatus && this.paymentStatus !== 'approved') {
      this.billingDetails = this.contact;
      const paymentType = this.gd?.paymentType?.toLocaleLowerCase();
      if (this.gd.attemptCount >= environment.MAX_PAYMENT_ALLOWED_ATTEMPTS) {
        setTimeout(() => {
          sessionStorage.clear();
          this.merchantResponseComponent.redirectToMerchantRejectURL('', {});
        }, 300);
      } else if(paymentType === 'cc'){
        document.documentElement.style.setProperty('--cc_load_click','block')
        this.creditCardComponentLoad = true;
        this.status = this.paymentStatus;
        this.selectPymntType='cc';
        this.alipayStatus = undefined;
      } else if (paymentType === 'alipay') {
        this.alipayStatus  = this.paymentStatus;
        this.status = undefined;
        this.selectPymntType= 'alp';
      } else {
        this.status = undefined;
        this.selectPymntType= 'pp';
      }
    }
    if (this.paymentStatus === 'approved') {
      setTimeout(() => {
        sessionStorage.clear()
        this.merchantResponseComponent.redirectToMerchantSuccessURL('', {});
      }, 300);
    }
  }

  getBlockedPaymentMethodNames(): Array<string> {
    let paymentMethodNames: Array<string> = [];
    if (this.gd && this.gd.oscConfig) {
      const oscConfig: OscConfig = this.gd.oscConfig;
      if (oscConfig.blockPymtOpt && oscConfig.blockPymtOpt.length > 0) {
        const blockPaymentOptions = oscConfig.blockPymtOpt.filter(pymtOpt => pymtOpt.country && (pymtOpt.country.countryIsoCode === this.orderData.shippingAddress.country ||
          pymtOpt.country.countryIsoCode === this.orderData.order.testCenterCountryCode)
        );
        if (blockPaymentOptions && blockPaymentOptions.length > 0) {
          for (const blockPaymentOption of blockPaymentOptions) {
            if (blockPaymentOption.paymentMethod && blockPaymentOption.paymentMethod.paymentMethodName) {
              paymentMethodNames.push(blockPaymentOption.paymentMethod.paymentMethodName);
            }
          }
        }
      }
    }
    return paymentMethodNames;
  }

  isPaypalEnabled(): boolean {
    let oscConfig: OscConfig;
    let blockedPaymentMethodNames: Array<string>;
    if (this.gd && this.gd.oscConfig && this.gd.sourceType && 'subscription' !== this.gd.sourceType.toLowerCase()) {
      oscConfig = this.gd.oscConfig;
      blockedPaymentMethodNames = this.getBlockedPaymentMethodNames();
    }
    if (oscConfig && oscConfig.paypalEnabled && 'CFHOP' === oscConfig.hopId &&
      blockedPaymentMethodNames && blockedPaymentMethodNames.indexOf('Paypal') < 0) {
      this.paypalEnabled = true;
    }
    return this.paypalEnabled;
  }

  isAlipayEnabled(): boolean {
    let oscConfig: OscConfig;
    let blockedPaymentMethodNames: Array<string>;
    if (this.gd?.oscConfig?.alipayEnabled && this.orderData && this.orderData?.sourceType.toLowerCase() != 'subscription') {
      oscConfig = this.gd.oscConfig;
      blockedPaymentMethodNames = this.getBlockedPaymentMethodNames();
    }
    if (oscConfig && oscConfig.alipayEnabled && blockedPaymentMethodNames && blockedPaymentMethodNames.indexOf('ALIPAY') < 0) {
      this.alipayEnabled = true;
    }
    return this.alipayEnabled;
  }

  openDialog() {
    this.dialog.open(AlipayLearnMoreDialogComponent);
  }

  submitPymntDetail() {
    console.log(this.selectPymntType);
  }
}
