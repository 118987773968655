<div class="alert alert-danger" role="alert" *ngIf="alipayStatus && alipayStatus !== 'approved'">
    <span *transloco="let t">{{t('alipay.error.payment.decline')}}</span>
</div>

<div class="card-body" *transloco="let t">
    <app-merchant-response></app-merchant-response>
    <form [formGroup]="billingInformation">
        <fieldset>
            <legend>
              <h3 class="pb-2 pymnt_cc__header font_family__standard">{{t('billing.info')}}</h3>
            </legend>
        <ng-container *ngIf=" gd?.oscConfig?.displayBillingName">
            <app-billing-name-information formGroupName="nameDetails" [orderData]="orderData" [spcOscCode]="spcOscCode"></app-billing-name-information>
        </ng-container>
        <ng-container *ngIf="gd?.oscConfig?.displayBillingAddress">
            <app-billing-address-information formGroupName="addressDetails" [orderData]="orderData" [spcOscCode]="spcOscCode"></app-billing-address-information>
        </ng-container>
        </fieldset>
    </form>
    <div class="col-lg-12">
        <div class="col-lg-12 pt-2 sub-text alipay-font font_family__standard">
            <div class="lock">
                <fa-icon [icon]="faLock"></fa-icon>
            </div>
            {{t('billing.ets.pymt.text')}}
        </div>
        <div class="col-lg-12">
            <button id="submit" [ngClass]="billingInformation.invalid ? 'disabled-bkg-alipay' : 'btn--primary-alipay'" class="processing-button" [disabled]="billingInformation?.invalid" (click)="createAlipayPayment()">
                <fa-icon [icon]="faLock"></fa-icon>
                <span class="cc_submit font_family__standard">{{t('btn.process.payment')}} (${{orderData?.amount | number : '1.2-2'}} <span>USD</span>)</span>
            </button>
        </div>
    </div>
</div>
