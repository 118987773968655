<div [ngClass]="oscCodes.includes(spcOscCode)?'tofel-rounded-card-checkout':'rounded-card-checkout'" *transloco="let t">
    <h2 class="checkout_li_title font_family__narrow">{{t('order.summary')}}</h2>
    <div class="checkout_lineitems_count" *ngIf="orderData?.lineItems?.length > 0">
        <h3 class="checkout_lineitems_count_info font_family__standard">
          {{ orderData?.lineItems.length }} {{t('checkout.items')}}
        </h3>
    </div>
    <section *ngIf="orderData?.lineItems?.length > 0">
        <div class="card__lineitem_details" *ngIf="expanddetails === false">
            <p class="registration__title font_family__standard">
                {{ orderData?.lineItems[0]?.productName }}
            </p>
            <div class="registration_title_desc font_family__standard">
              <p *ngFor="let desc of splitDesc(orderData?.lineItems[0]?.productDescription)">
                {{ desc }}
              </p>
            </div>
          <dl class="dl-con">
            <dt>{{t('checkout.qty')}}:</dt>
            <dd>{{ orderData?.lineItems[0]?.quantity }}</dd>
            <dt>{{t('checkout.unit.price')}}:</dt>
            <dd>${{ orderData?.lineItems[0]?.unitPrice | number : "1.2-2" }}</dd>
            <dt>{{t('checkout.tax')}}:</dt>
            <dd><ng-container *ngIf="orderData?.lineItems[0]?.taxAmount"> ${{ orderData?.lineItems[0]?.taxAmount | number : "1.2-2" }} </ng-container></dd>
            <dt>{{t('checkout.unit.total')}}:</dt>
            <dd>${{ orderData?.lineItems[0]?.total| number : "1.2-2" }}</dd>
          </dl>
        </div>
        <div *ngFor="let lineItem of orderData?.lineItems;index as i">
            <div class="card__lineitem_details" aria-labelledby="tab-{{i}}" role="tabpanel" *ngIf="expanddetails !== false">
                <p class="registration__title font_family__standard" *ngIf="i===1;else itemtmpl" id="panel-{{i}}"  [attr.tabindex]="tabIndex">
                    {{ lineItem?.productName }}
                </p>
                <ng-template #itemtmpl>
                    <p class="registration__title font_family__standard" id="panel-{{i}}" >
                        {{ lineItem?.productName }}
                    </p>
                </ng-template>
                <div class="registration_title_desc font_family__standard">
                  <p *ngFor="let desc of splitDesc(lineItem?.productDescription)">
                    {{ desc }}
                  </p>
                </div>
              <dl class="dl-con">
                <dt>{{t('checkout.qty')}}:</dt>
                <dd>{{ lineItem?.quantity }}</dd>
                <dt>{{t('checkout.unit.price')}}: </dt>
                <dd>${{ lineItem?.unitPrice | number : "1.2-2" }}</dd>
                <dt>{{t('checkout.tax')}}:</dt>
                <dd><ng-container *ngIf="lineItem?.taxAmount"> ${{ lineItem?.taxAmount | number : "1.2-2" }} </ng-container></dd>
                <dt>{{t('checkout.unit.total')}}: </dt>
                <dd>${{ lineItem?.total| number : "1.2-2" }}</dd>
              </dl>
            </div>
        </div>
    </section>
    <div id = "expand" #expandBtn class="expand_icon_details" role="button"
    id="tab-0" aria-controls="panel-1" aria-expanded="false" aria-orientation="vertical"  *ngIf="orderData?.lineItems?.length > 1 && expanddetails === false" (click)="expandDetails(true)" (keydown)="expandDetailsOnKey($event)" tabindex="0">
        <div>
            <span class="details__expand font_family__standard">{{t('checkout.expand.details')}}</span>
        </div>
        <img class="checkout__down_angle" src="../../assets/svg/caret_down.svg" alt="down" />
    </div>
    <div id = "collapse" #collapseBtn class="expand_icon_details" role="button" 
    id="tab-1" aria-controls="panel-1" aria-expanded="true" *ngIf="orderData?.lineItems?.length > 1 && expanddetails !== false" (click)="collapseDetails(false)" (keydown)="collapseDetailsOnKey($event)" tabindex="0">
        <div>
            <span class="details__expand font_family__standard">{{t('checkout.collapse.details')}}</span>
        </div>
        <img class="checkout__down_angle" src="../../assets/svg/caret_up.svg" alt="up" />
    </div>
    <div class="checkout__total_amount_details">
      <dl class="dl-con">
        <dt class="total__title font_family__standard">{{t('total')}}</dt>
        <dd class="total__title font_family__standard doller_total__title">${{ orderData?.amount | number : "1.2-2" }} <span class="total_title_usd font_family__standard">USD</span></dd>
      </dl>
    </div>
</div>
