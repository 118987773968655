<header class="ets-header">
  <div class="navbar navbar-dark navbar-expanded ets-main-banner">
      <div class="container-fluid">
          <div></div>
          <div *ngIf="!oscCodes.includes(spcOscCode)" class="navbar-brand d-flex align-items-center center">
              <img class="navbar-logo" alt="ETS Logo" src="../../assets/img/ETS_header_logo.svg" />
          </div>
          <div *ngIf="oscCodes.includes(spcOscCode)" class="navbar-brand d-flex align-items-center center">
            <img class="navbar-logo" alt="TOEFL Logo" src="../../assets/svg/TOEFL.svg" />
          </div>
          <div class="navbar d-flex align-items-right right" *transloco="let t">
           <select id="selLang" [ngClass]="oscCodes.includes(spcOscCode) ? 'tofel-tgl-lan' : 'tgl-lan'" (change)="changeLanguage($event)" aria-label="Select Language">
                <option value="en" [selected]="displayLang==='en'" aria-label="English">{{t('language.selector.en')}}</option>
                <option value="zh-cn" [selected]="displayLang==='zh-cn'" ria-label="Mandarin">{{t('language.selector.zhcn')}}</option>
            </select>
          </div>
      </div>
  </div>
</header>
