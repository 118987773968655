<div class="app__header__foter_align">
    <!-----Payment header ------->
    <app-header [spcOscCode]="orderDetails?.order?.originatingSystemCode"></app-header>

    <!-----Payment Body ------->
    <div class="app_body" *transloco="let t">
        <div *ngIf="loading" role="alert" aria-live="assertive">
            <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-spin-clockwise-fade">
                <p style="font-size: 20px; color: white;">
                    {{t('loading')}}
                    <span aria-hidden="true">...</span>
                </p>
            </ngx-spinner>
        </div>
        <div *ngIf="isModeOfReceiptSelectNeeded" class="row justify-content-center py-5">
            <div class="col-md-6">
                <label for="app-mode-of-receipt" class="control-label"> <span class="required-asterisk">*</span>Please identify how you were contacted to help this customer:</label>
                <select id="app-mode-of-receipt" class="form-select form-select-md" name="app-mode-of-receipt" required (change)="onModeOfReceiptSelect($event)">
                    <option value>-- Please Select --</option>
                    <option value="F">FAX</option>
                    <option value="I">In Person</option>
                    <option value="M">Mail</option>
                    <option value="T">Telephone</option>
                    <option value="V">VRS</option>
                </select>
            </div>
        </div>
        <div *ngIf="!isOscSessionIdValid || !isTransactionValid" class="container">
            <div class="row justify-content-center py-5">
                <div class="col-md-6">
                    <label for="app-invalid-transaction" class="control-label">
                        {{t('technical.issue')}}
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="isShowPage()">
            <div class="container-fluid mt-4 mb-4 app__width">
                <div class="row">
                    <div class="col-12">
                        <fa-icon [icon]="faLeft" class="back_icon__decor"></fa-icon>
                        <a class="link app_order_back font_family__default" href="#" (click)="backToOrder(); $event.preventDefault()">
                            <span class="app_back_to_app font_family__standard">{{t('back.link')}}</span>
                        </a>
                    </div>
                </div>
                <div class="row mb-4 space_align_abandoned_desktop">
                    <div class="col-12"></div>
                </div>
                <div class="row mb-2 space_align_abandoned_mobile">
                    <div class="col-12"></div>
                </div>
                <app-secure-checkout [spcOscCode]="orderDetails?.order?.originatingSystemCode"></app-secure-checkout>
                
                <div [ngClass]="desktopView === 'mobile' ? 'row small_screen__order_info': 'row large_screen'">
                    <div id="appOrderRadioButtons" [ngClass]="desktopView === 'mobile' ? 'col-mb-12 app_order_checkout_info':'col-lg-8'">
                        <app-order [orderData]="orderDetails" [paymentType]="paymentType" [contact]="contact" 
                        [paymentStatus]="paymentStatus" [spcOscCode]="orderDetails?.order?.originatingSystemCode"></app-order>                
                    </div>
                    <div id = "appCheckoutInfo" [ngClass]="desktopView ==='mobile' ? '' : 'col-lg-4 mb-4 app_order_checkout_info'">
                        <app-checkout [orderData]="orderDetails" [spcOscCode]="orderDetails?.order?.originatingSystemCode"></app-checkout>
                        <app-order-info [orderData]="orderDetails" [spcOscCode]="orderDetails?.order?.originatingSystemCode"></app-order-info>
                    </div>
                </div>
        </div>
    </div>

    <!-----Payment Footer ------->
    <app-footer [spcOscCode]="orderDetails?.order?.originatingSystemCode"></app-footer>
</div>
