import {
  provideTransloco,
  TranslocoModule
} from '@jsverse/transloco';
import { isDevMode, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco/transloco-loader';
import {provideTranslocoMessageformat} from "@jsverse/transloco-messageformat";


@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideTransloco({
        config: {
          availableLangs: ['en', 'zh-cn'],
          defaultLang: 'en',
          reRenderOnLangChange: true,
          prodMode: !isDevMode(),
          fallbackLang:'en',
          missingHandler: {
            useFallbackTranslation: true,
            logMissingKey: false,
          },
          failedRetries: 1,
          flatten: {
            aot: !isDevMode(),
          },
        },
        loader: TranslocoHttpLoader
      }),
    provideTranslocoMessageformat(),
  ],
})
export class TranslocoRootModule {}
