<div class="mb-4 mt-2">
    <!-- payment redirect form -->
    <app-merchant-response></app-merchant-response>
    <div class="alert alert-danger" role="alert"
    *ngIf="paymentStatus && paymentStatus !== 'approved'">
        {{ errorMessage }}
    </div>
    <div class="card-body" *transloco="let t"> 
        <form id="ccForm" name="ccForm" (ngSubmit)="(ccFormData.form.valid)" #ccFormData="ngForm">
            <fieldset>
                <legend>
                  <h3 class="pymnt_cc__header font_family__standard">{{t('cc.info')}}</h3>
                </legend>
            <!-- credit card type allowd drop down list -->
            <div class="row">
                <div class="col-md-12">
                    <label for="cc-option" class="control-label">
                        <span class="required-asterisk">
                            <span class="cc_details__label font_family__standard">{{t('cc.type')}}</span> *
                        </span>
                    </label>
                    <select id="cc-option" class="form-select form-select-md" name="cardType" required="true" [(ngModel)]="model.cardType" #cardType="ngModel" (change)="onCardTypeChange(cardType.value)">
                        <option value="001" *ngIf="gd?.oscConfig?.visaEnabled && showCreditcardType('Visa')">
                            {{t('cc.type.visa')}}
                        </option>
                        <option
                            value="002"
                            *ngIf="
                gd?.oscConfig?.masterCardEnabled &&
                showCreditcardType('MasterCard')
              "
                        >
                            {{t('cc.type.mastercard')}}
                        </option>
                        <option value="003" *ngIf="gd?.oscConfig?.amexEnabled && showCreditcardType('Amex')">
                            {{t('cc.type.amex')}}
                        </option>
                        <option value="007" *ngIf="gd?.oscConfig?.jcbEnabled && showCreditcardType('JCB')">
                            {{t('cc.type.jcb')}}
                        </option>
                        <option
                            value="004"
                            *ngIf="
                gd?.oscConfig?.discoverEnabled && showCreditcardType('Discover')
              "
                        >
                        {{t('cc.type.discover')}}
                        </option>
                        <option
                            value="006"
                            *ngIf="
                gd?.oscConfig?.unionPayEnabled && showCreditcardType('UnionPay')
              "
                        >
                        {{t('cc.type.union.pay')}}
                        </option>
                        <option
                            value="005"
                            *ngIf="
                gd?.oscConfig?.dinersClubEnabled &&
                showCreditcardType('Diners Club')
              "
                        >
                        {{t('cc.type.dinner.club')}}
                        </option>
                        <option
                            value="008"
                            *ngIf="
                gd?.oscConfig?.rupayGlobalEnabled &&
                showCreditcardType('RuPay Global')
              "
                        >
                        {{t('cc.type.rupay')}}
                        </option>
                    </select>
                    <div *ngIf="cardType.invalid && (cardType.dirty || cardType.touched)" class="tip" role="alert">
                        <div *ngIf="cardType.errors.required">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.type')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Creditcard fields for Fiserv -->
            <div class="row mt-3" *ngIf="!isStubEnabled">
                <div class="col-lg-12 col-md-12">
                    <label for="cc-card" class="control-label">
                        <span class="cc_details__label font_family__standard">{{t('cc.number')}}</span>
                        <span class="required-asterisk">*</span>
                    </label>
                    <div id="cc-card" class="form-controls payment-fields disabled empty" minlength="16" maxlength="19" data-cc-card></div>
                </div>
                
                <div *ngIf="cardErrors?.touched || cardErrors?.length <= 0" class="fiserv-tip" role="alert">
                    <div *ngIf="cardErrors?.length > 0 && (cardErrors?.length < 15 || cardErrors?.length > 16)">
                        <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.number.len')}}</p>
                    </div>
                    <div *ngIf="(cardErrors?.length === 0 && cardErrors.empty) || (cardErrors.length >= 15 && !cardErrors?.valid)">
                        <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.number.empty')}}</p>
                    </div>
                </div>
            </div>
            <div class="cc_exp_cvv_align" *ngIf="!isStubEnabled">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <label for="cc-expiry" class="control-label">
                        <span class="cc_details__label font_family__standard">{{t('cc.expdate')}}</span>
                        <span class="required-asterisk">*</span>
                    </label>
                    <div id="cc-expiry" class="form-controls payment-fields disabled empty" labelledby="expiration date" data-cc-exp></div>
                    
                    <div *ngIf="expErrors?.touched || expErrors?.length <= 0" class="fiserv-tip" role="alert">
                        <div *ngIf="expErrors?.length < 5 || expErrors?.length === 0 || expErrors.empty">
                           <p *ngIf="!ccShuffleDisable"> {{t('cc.error.card.exp')}}</p>
                        </div>
                        <div *ngIf="expErrors?.length >= 5 && !expErrors?.valid">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.exp.date.range')}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-4" *ngIf="showCvvForCC && isShowCvv()">
                    <label for="cc-cvv" class="control-label cvv__tooltip">
                        <span class="cc_details__label font_family__standard">{{t('cc.cvv')}}</span>
                        <span class="required-asterisk">*</span>
                        <app-cvv-tooltip></app-cvv-tooltip>
                    </label>
                    <div class="form-controls payment-fields disabled empty" aria-describedby="cvv-tip" id="cc-cvv" data-cc-cvv></div>
                    <div *ngIf="cvvErrors?.touched || cvvErrors?.length <= 0" class="fiserv-tip" role="alert">
                        <div *ngIf="cvvErrors?.length > 0 && (cvvErrors?.length < 3 || cvvErrors?.length > cvvErrors?.maxLength)">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.cvv')}}</p>
                        </div>
                        <div *ngIf="cvvErrors?.length === 0 && cvvErrors.empty">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.cvv.empty')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!isStubEnabled">
                <div class="col-lg-12">
                    <label for="cc-name" class="control-label">
                        <span class="cc_details__label font_family__standard">{{t('cc.name.on.card')}}</span>
                        <span class="required-asterisk">*</span>
                    </label>
                    <div class="form-controls payment-fields disabled empty" id="cc-name" data-cc-name></div>

                    <div *ngIf="nameErrors?.touched || nameErrors?.length <= 0" class="fiserv-tip" role="alert">
                        <div *ngIf="nameErrors?.length === 0 && nameErrors.empty">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.full.name')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Creditcard fields for stubing -->
            <div class="row mt-3" *ngIf="!!isStubEnabled">
                <div class="col-lg-12 col-md-12 cc__stub_" *ngIf="selectedCard === '003'; else ccsixteen">
                    <label for="stubCardNumber"> <span class="required-asterisk">*</span>{{t('cc.number')}} </label>
                    <input id="stubCardNumber" class="form-control form-control-lg" name="stubCardNumber" required pattern="^[0-9]+$" minlength="15" maxlength="19" ngModel #stubCardNumber="ngModel" />
                    <div
                        *ngIf="
              stubCardNumber.invalid &&
              (stubCardNumber.dirty || stubCardNumber.touched)
            "
                        class="tip"
                        role="alert"
                        aria-describedby="card-number"
                    >
                        <div *ngIf="stubCardNumber.errors?.minlength">
                           <p *ngIf="!ccShuffleDisable"> {{t('cc.error.card.number.len')}}</p>
                        </div>
                        <div *ngIf="stubCardNumber.errors?.required">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.number.empty')}}</p>
                        </div>
                        <div *ngIf="stubCardNumber.errors?.pattern">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.full.name.special')}}</p>
                        </div>
                    </div>
                </div>
                <ng-template #ccsixteen>
                    <div class="col-lg-12 col-md-12">
                        <label for="stubCardNumber">
                            <span class="cc_details__label font_family__standard">{{t('cc.number')}}</span>
                            <span class="required-asterisk">*</span>
                        </label>
                        <input id="stubCardNumber" class="form-control form-control-lg" name="stubCardNumber" required pattern="^[0-9]+$" minlength="16" maxlength="19" ngModel #stubCardNumber="ngModel" />
                        <div
                            *ngIf="
                stubCardNumber.invalid &&
                (stubCardNumber.dirty || stubCardNumber.touched)
              "
                            class="tip"
                            role="alert"
                            aria-describedby="stubCardNumber"
                        >
                            <div *ngIf="stubCardNumber.errors?.minlength">
                               <p *ngIf="!ccShuffleDisable"> {{t('cc.error.card.number.len')}}</p>
                            </div>
                            <div *ngIf="stubCardNumber.errors?.required">
                               <p *ngIf="!ccShuffleDisable"> {{t('cc.error.card.number.empty')}}</p>
                            </div>
                            <div *ngIf="stubCardNumber.errors?.pattern">
                                <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.full.name.special')}}</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <div class="cc_exp_cvv_align">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <label for="stubExpDate">
                          <span class="cc_details__label font_family__standard">{{t('cc.expdate')}}</span>
                          <span class="required-asterisk">*</span>
                        </label>
                        <input id="stubExpDate" class="form-control form-control-lg" name="stubExpDate" pattern="^[0-9/]+$" minlength="5" maxlength="5" ngModel #stubExpDate="ngModel" required (input)="changeStubExpDate($event)"/>
                        <div
                            *ngIf="IsExpDateValid && (stubExpDate.invalid && (stubExpDate.dirty || stubExpDate.touched))"
                            class="tip"
                            role="alert"
                            aria-describedby="stubExpDate"
                        >
                            <div *ngIf="stubExpDate.errors?.required">
                                <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.exp')}}</p>
                            </div>
                            <div *ngIf="stubExpDate.errors?.pattern">
                                <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.full.name.special')}}</p>
                            </div>
                        </div>
                        <div *ngIf="!IsExpDateValid" class="tip" role="alert">
                            <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.exp.date.range')}}</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-4" *ngIf="showCvvForCC && isShowCvv()">
                        <label for="stubCvv" class="control-label cvv__tooltip">
                            <span class="cc_details__label font_family__standard">{{t('cc.cvv')}}</span>
                            <span class="required-asterisk">*</span>

                            <app-cvv-tooltip></app-cvv-tooltip>
                        </label>
                        <input
                            id="stubCvv"
                            class="form-control form-control-lg"
                            name="stubCvv"
                            pattern="^[0-9]+$"
                            minlength="3"
                            maxlength="4"
                            ngModel
                            data-cc-cvv
                            #stubCvv="ngModel"
                            required
                        />
                        <div *ngIf="stubCvv.invalid && (stubCvv.dirty || stubCvv.touched)" class="tip" role="alert" aria-describedby="stubCvv">
                            <div *ngIf="stubCvv.errors?.minlength">
                                <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.cvv')}}</p>
                            </div>
                            <div *ngIf="stubCvv.errors?.required">
                                <p *ngIf="!ccShuffleDisable">{{t('cc.error.card.cvv.empty')}}</p>
                            </div>
                            <div *ngIf="stubCvv.errors?.pattern">
                               <p *ngIf="!ccShuffleDisable"> {{t('cc.error.card.full.name.special')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!!isStubEnabled">
                <div class="col-lg-12 mb-3">
                    <label for="stubCcName">
                        <span class="cc_details__label font_family__standard">{{t('cc.name.on.card')}} </span>
                        <span class="required-asterisk">*</span>
                    </label>
                    <input id="stubCcName" class="form-control form-control-lg" name="stubCcName" required ngModel #chName="ngModel" maxlength="96" />
                    <div *ngIf="chName.invalid && (chName.dirty || chName.touched)" class="tip" role="alert" aria-describedby="stubCcName">
                        <div *ngIf="chName.errors.required">
                            {{t('cc.error.card.full.name')}}
                        </div>
                    </div>
                </div>
            </div>
            </fieldset>

            <!-- Billing address section -->
            <form [formGroup]="billingInformation">
                <fieldset>
                    <legend>
                      <h3 class="mt-4 mb-2 pe-2 pymnt_cc__header font_family__standard">{{t('billing.info')}}</h3>
                    </legend>
                <app-billing-address-information [orderData]="orderData" [requiredFields]="getRequiredFields()" [addrLabel]="'billing.same.shipping.info'" formGroupName="addressDetails" [spcOscCode]="spcOscCode"></app-billing-address-information>
                </fieldset>
            </form>
            <div class="cc__payment_description">
                <div class="col-md-12">
                    <span class="cc__description_pymnt font_family__standard">{{t('cc.select.currency')}}</span>
                </div>
                <div class="col-12 sub-text ets__process__lock">
                    <div class="lock">
                        <fa-icon [icon]="faLock"></fa-icon>
                    </div>
                    <div class="cc__description__mob_view">
                        <span class="cc__description_pymnt font_family__standard">{{t('billing.ets.pymt.text')}} </span>

                        <button tabindex="0" type="button" role="button" class="link link-color" (click)="openLearnMore(dsmodal)">
                            <span class="cc__popup font_family__standard">{{t('learn.more')}}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="col-lg-12">
                    <div id="payButton" *ngIf="!isStubEnabled">
                        <!-- Button to submit the data to fiserv -->
                        <ng-container *ngIf="ccFormData?.invalid || payButton?.disabled">
                            <button
                                id="submit"
                                [ngClass]="
                  ccFormData.invalid || payButton?.disabled
                    ? 'disabled-bkg-cc'
                    : 'btn--primary-cc'
                "
                                disabled
                                data-submit-btn
                                class="col-lg-12"
                            >
                                <fa-icon [icon]="faLock"></fa-icon>
                                <span class="cc_submit font_family__standard">{{t('btn.process.payment')}} (${{orderData?.amount | number : '1.2-2'}} <span>USD</span>)</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!(ccFormData?.invalid || payButton?.disabled)">
                            <button
                                id="submit"
                                [ngClass]="
                  ccFormData.invalid || payButton?.disabled || !isValidForVisa()
                    ? 'disabled-bkg-cc'
                    : 'btn--primary-cc'
                "
                                [attr.disabled]="
                  ccFormData.invalid || !isValidForVisa() ? 'disabled' : null
                "
                                data-submit-btn
                            >
                                <fa-icon [icon]="faLock"></fa-icon>
                                <span class="cc_submit font_family__standard">{{t('btn.process.payment')}} (${{orderData?.amount | number : '1.2-2'}} <span>USD</span>)</span>
                            </button>
                        </ng-container>
                    </div>
                    <div id="stubPayButton" *ngIf="isStubEnabled">
                        <!-- Button to submit the data to stubing -->
                        <button
                            type="button"
                            [ngClass]="
                ccFormData.invalid || !isValidForVisa()
                ? 'disabled-bkg-cc'
                : 'btn--primary-cc'
              "
                            [attr.disabled]="
                ccFormData.invalid || !isValidForVisa() ? 'disabled' : null
              "
                            (click)="submitStubForm(ccFormData)"
                        >
                            <span *ngIf="ccFormData.invalid || !isValidForVisa()">
                                <fa-icon [icon]="faLock"></fa-icon>
                            </span>
                            <span class="cc_submit font_family__standard">{{t('btn.process.payment')}} (${{orderData?.amount | number : '1.2-2'}} <span>USD</span>)</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- 3DS form -->
<div [style.display]="'none'" class="secure-3ds-form-container" *ngIf="secure3dsFormData">
    <div [innerHtml]="secure3dsFormData"></div>
</div>
<!-- ACS Form -->
<div [style.display]="'none'" class="auth-challenge-container" *ngIf="authChallengeParams">
    <form id="epms-acs-redirect-form" ngNoForm method="POST" action="{{ authChallengeParams.acsURL }}" #authChallengeForm>
        <div *ngIf="authChallengeParams.payerAuthenticationRequest">
            <input type="hidden" name="PaReq" value="{{ authChallengeParams.payerAuthenticationRequest }}" />
            <input type="hidden" name="MD" value="{{ authChallengeParams.merchantData }}" />
        </div>
        <div *ngIf="authChallengeParams.creq">
            <input type="hidden" name="creq" value="{{ authChallengeParams.creq }}" />
            <input type="hidden" name="threeDSSessionData" value="{{ authChallengeParams.sessionData }}" />
        </div>
        <input type="hidden" name="TermUrl" value="{{ authChallengeParams.termURL }}" />
    </form>
</div>
<!-- DCC Confirmation-->
<ng-template #dccModal let-modal *transloco="let t">
    <div class="font_family_default__sans" [ngClass]="oscCodes.includes(spcOscCode)?'tofel-modal-header':'modal-header'">
        <h4 class="font_family__narrow" [ngClass]="oscCodes.includes(spcOscCode)?'tofel-amt-header':'amt-header'">{{t('dcc.choice')}}</h4>
    </div>
    <div class="inner-modal">
        <table class="dcc amt-labels">
            <tr>
                <td>
                    <div class="flag pe-4">
                        <img class="img-fluid" src="../../assets/img/u3579.png" alt="US Flag" />
                    </div>
                </td>
                <td>
                    <div class="amount">
                        <span class="medium">{{ dccResponse.currency }} </span>
                        <span class="medium">{{ dccResponse.grandTotalAmount }}</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div class="flag pe-4">
                        <img class="img-fluid" src="../../assets/img/{{ dccResponse.foreignCurrency }}.png" alt="{{ dccResponse.foreignCurrency }} Flag" />
                    </div>
                </td>
                <td>
                    <div class="amount">
                        <span class="medium">{{ dccResponse.foreignCurrency }} </span>
                        <span class="medium"> {{ dccResponse.foreignCurrencyGrandTotalAmount }}</span>
                        <br />
                        <span class="medium">1 {{ dccResponse.currency }} = {{ dccResponse.exchangeRate }} {{ dccResponse.foreignCurrency }} {{t('dcc.includes')}} {{ dccResponse.marginRatePercentage }}{{t('dcc.margin')}}</span>
                    </div>
                </td>
            </tr>
        </table>
        <div class="d-flex justify-content-between">
            <button [ngClass]="oscCodes.includes(spcOscCode) ?'btn-tofel':'btn--primary amt-btn'" (click)="exitdcc(false); modal.close()">
                {{t('dcc.payin')}} {{ dccResponse.currency }}
            </button>
            <button [ngClass]="oscCodes.includes(spcOscCode) ?'btn-tofel':'btn--primary amt-btn'" (click)="exitdcc(true); modal.close()">
                {{t('dcc.payin')}} {{ dccResponse.foreignCurrency }}
            </button>
        </div>
    </div>
</ng-template>
<!-- Learn more modal section -->
<ng-template #dsmodal let-modal >
    <div class="font_family_default__sans" [ngClass]="oscCodes.includes(spcOscCode)?'tofel-modal-header':'modal-header'" *transloco="let t">
        <h4 id="3DS_Disclaimer" tabindex="-1" class="font_family__narrow" [ngClass]="oscCodes.includes(spcOscCode)?'tofel-header-3ds':'header-3ds'">
            <ng-container *ngIf="oscCodes.includes(spcOscCode); else question">
                <img class="i-tooltip" src="../../assets/svg/cvvtip.svg"/>
            </ng-container>
            <ng-template #question>
                <fa-icon class="question-3ds" [icon]="faQuestionCircle"></fa-icon>
            </ng-template>
            {{t('cc.3ds.info')}}</h4>
        <button type="button" (click)="modal.close()" aria-label="close" class="btn-close modal_content__tooltip_toefl btn-close me-2"></button>
    </div>
    <div class="inner-modal" *transloco="let t">
        <p class="ds-content font_family__narrow">
            {{t('cc.3ds.text')}}
        </p>
        <ul class="icons d-flex justify-content-between no-bullets" role="list">
            <li class="credit-card" role="listitem">
                <img class="img-fluid" src="../../assets/img/secure-120x120-visa-secure.png" alt="Visa Secure" tabindex="0" />
            </li>
            <li class="credit-card" role="listitem">
                <img class="img-fluid" src="../../assets/img/secure-120x120-mc-securecode.png" alt="MasterCard SecureCode" tabindex="0" />
            </li>
            <li class="credit-card" role="listitem">
                <img class="img-fluid" src="../../assets/img/secure-120x120-amex-safekey.png" alt="American Express SafeKey" tabindex="0" />
            </li>
            <li class="credit-card" role="listitem">
                <img class="img-fluid" src="../../assets/img/secure-120x120-discover-protectbuy.png" alt="Discover Protect Buy" tabindex="0" />
            </li>
        </ul>        
    </div>
</ng-template>
