<footer [ngClass]="oscCodes.includes(spcOscCode)?'tofel-footer footer-align':'footer-align'">
  <div class="container-fluid footer-text-align font_family__standard" *transloco="let t">
    <p class="line__font_text font_family__standard">
      <span *ngIf="oscCodes.includes(spcOscCode)">
        <a id="#footer" href="https://www.ets.org/contact/testing-programs/toefl.html" target="_blank">{{t('footer.contact.us')}}</a>
        <span class="pipe-color">&nbsp;|&nbsp;</span>
      </span>
      <a id="#footer" href="https://www.ets.org/legal" target="_blank">{{t('footer.link.legal')}}</a>
      <span class="pipe-color">&nbsp;|&nbsp;</span>
      <a href="https://www.ets.org/legal/privacy" target="_blank">{{t('footer.link.privacy.security')}}</a>
      <span class="pipe-color">&nbsp;|&nbsp;</span>
      <a href="https://www.ets.org/legal/trademarks/owned" target="_blank">{{t('footer.link.ets.trademark')}}</a>
      <span *ngIf="oscCodes.includes(spcOscCode)">
        <span class="pipe-color">&nbsp;|&nbsp;</span>
        <a href="https://get.adobe.com/reader/" target="_blank">{{t('footer.adobe.reader')}}</a>
      </span>
    </p>
    <p>{{t('footer.copyright')}}</p>
  </div>
</footer>