import {  Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Constants } from '../constants/constants';
import { Transaction } from '../models/transaction';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { LoadingService } from '../services/loading.service';
import { PaymentRequestService } from '../services/payment-request.service';
import { GlobalAppData } from '../models/global-app-data';
import { PaymentService } from '../services/payment.service';
import { getQueryParamByName, updateQueryStringParam } from '../common/utils';
import { MerchantResponseData } from '../models/merchant-models';
import { MerchantResponseComponent } from '../merchant-response/merchant-response.component';
import { SharedService } from '../services/shared.service';
import { SegmentService } from '../services/segment.service';
import { EventTypes } from '../models/enums/event-types';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-alipay',
  templateUrl: './alipay.component.html',
  styleUrls: ['./alipay.component.css'],
})
export class AlipayComponent implements OnInit {
  billingInformation: FormGroup;
  @Input() orderData: Transaction;
  @ViewChild(MerchantResponseComponent) merchantResponseComponent;
  faLock = faLock;
  @Input('alipayStatus') alipayStatus: string;
  
  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
  @Input() spcOscCode: String;

  constructor(
    private fb: FormBuilder,
    private _loading: LoadingService,
    private paymentRequestService: PaymentRequestService,
    private paymentService: PaymentService,
    public gd: GlobalAppData,
    private sharedService: SharedService,
    private ss: SegmentService,
    private tranlocoService:TranslocoService
  ) {}

  ngOnInit(): void {
    this.initForm();
    if (this.alipayStatus && this.alipayStatus !== 'approved'){
      this.handleError();
    }
  }

  initForm() {
    this.billingInformation = this.fb.group({
      nameDetails: this.fb.group({
        firstName: new FormControl(null, [
          Validators.minLength(1),
          Validators.required,
        ]),
        lastName: new FormControl(null, [
          Validators.minLength(1),
          Validators.required,
        ]),
        sameAsShippingContactName: new FormControl(),
      }),
      addressDetails: this.fb.group({
        streetAddress1: new FormControl(null, Validators.required),
        streetAddress2: new FormControl(),
        city: new FormControl(null, Validators.required),
        country: new FormControl('', Validators.required),
        region: new FormControl({ value: null, disabled: true }),
        zipcode: new FormControl({ value: null, disabled: true }),
        company: new FormControl(null),
        phoneNumber: new FormControl(
          '',
          Validators.pattern(Constants.PHONENUMBER_VALIDATOR_PATTERN)
        ),
        email: new FormControl(
          '',
          Validators.pattern(Constants.EMAIL_VALIDATOR_PATTERN)
        ),
        sameAsShippingContactAddress: new FormControl(),
      }),
    });
  }

  fetchNewtrnsctnAtmptAliPay(): void {
    this.sharedService.getNewTransactionId(this.gd.transId)
       .then((res: string) => {
         this.gd.transId = res;
         this.gd.attemptCount++;
          updateQueryStringParam('t', res);
      }).catch((error) => {
        if (error && error.status && error.error) {
          console.debug(`error ap new txn attempt ${error?.error}`);
        }
      }).finally(() => {
        this._loading.setLoading(false,'internal');
      });
  }

  redirectToMerchant(urlVal: string): void {
    sessionStorage.clear()
    const paymentActionData = {
      url: urlVal,
      orderTransaction: this.gd.orderTransaction,
      merchantPaymentReq: {},
    } as MerchantResponseData;
    this.merchantResponseComponent.sendResponseToMerchant('ALIPAY', paymentActionData);
  }

  redirectToMerchantRejectURL(): void {
    let merchantRejectURL = this.gd.orderTransaction.order.merchantRejectURL;
    if (!merchantRejectURL) {
      merchantRejectURL = this.gd.oscConfig.rejectUrl;
    }
    this.redirectToMerchant(merchantRejectURL);
  }

  alipayTrnsctnDefaultAttempt(){
    const paymentReq = this.paymentRequestService.getBillingPaymentRequest(
      this.billingInformation,
      true
    );
    sessionStorage.setItem('page', this.gd.attemptCount.toLocaleString());
    sessionStorage.setItem('gd', Buffer.from(JSON.stringify(this.gd)).toString('base64'));
    sessionStorage.setItem('sessionid', getQueryParamByName('jSession'));
    this.paymentService
      .createPayment(paymentReq, this.gd.transId, '/alipay')
      .subscribe({
        next: (result: any) => {
          if (!(typeof result === 'undefined' || result === null) && result) {
            location.href = result;
          } else {
            this.handleError();
          }
        },
        error: (error: any) => {
          this.handleError();
        }
    });
  }

  createAlipayPayment(): void {
    this.gd.paymentType = 'alipay';
    this.ss.track(EventTypes.alipySubmitted);
    this._loading.setLoading(true,'internal');
    this.alipayTrnsctnDefaultAttempt();
  }

  handleError() {
    this.alipayStatus = 'paymentError';
    this.billingInformation.reset();
    this.tranlocoService.setActiveLang(this.gd?.orderTransaction?.order?.displayLanguage);
    if (this.gd.attemptCount < this.gd.oscConfig.maxRejectAtmptCount) {
      this.fetchNewtrnsctnAtmptAliPay();
    } else {
      this.redirectToMerchantRejectURL();
    }
  }
}
