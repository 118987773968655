<!-- payment redirect form -->
<app-merchant-response></app-merchant-response>
<div class="payment-details" *transloco="let t">
    <h2 class="pdetails_title font_family__narrow">{{t('payment.details')}}</h2>
    <p class="pdetails_title_sub font_family__standard">{{t('select.payment.method')}}</p>
    <div>
        <form (submit)="submitPymntDetail()">
            <section class="pymnt__section_cc">
                <div class="radio_inline__payments__cc" *ngIf="gd?.oscConfig?.ccPaymentEnabled">
                    <input id="cc" type="radio" required value="cc" name="pymntdetails"
                     [(ngModel)]="selectPymntType"
                     (change)="clearPaymemtStatus('cc')"
                     (change)="loadCreditCardComponentConditionally()"/>
                    <div class="label__inline_responsive">
                        <label class="radio-inline inline__cc font_family__standard" for="cc">{{t('cc')}}</label>
                        <ul class="cc_payment_types no-bullets ul-con" role="list">
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsvisa-copy.png" alt="Visa card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsmastercard-copy.png" alt="MasterCard card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsamex-copy.png" alt="American Express card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsdiscover-copy.png" alt="Discover card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsjcb-copy.png" alt="JCB card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsunionpay-copy.png" alt="UnionPay card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsdinersclub-copy.png" alt="Diners Club card payment option" class="pymnt_cc_amex_logo" tabindex="0" />
                          </li>
                          <li role="listitem">
                            <img src="../../assets/img/epms-payment-cardsrupay-copy.png" alt="RuPay card payment option" class="pymnt_cc_amex_logo_rupay" tabindex="0" />
                          </li>
                        </ul>                        
                    </div>
                </div>
            </section>
            <div id="cc_card_dom" *ngIf="creditCardComponentLoad === true" class="pymnt_details__cc_pymnt">
              <app-credit-card [ngStyle]="{ 'display': selectPymntType === 'cc' ? 'block' : 'none' }" [billingDetails]="billingDetails" [status]="status" 
              [disabledPaymentMethodNames]="getBlockedPaymentMethodNames()" 
              [orderData]="orderData" [spcOscCode]="spcOscCode"
              [selectPaymentType]="selectPymntType"></app-credit-card>
            </div>
            <div class="radio_inline__payments_pp" *ngIf="orderData && isPaypalEnabled()">
                <section class="pymnt__section_pp" *ngIf="!gd?.isStubEnabled" >
                    <input id="pp" type="radio" required value="pp" name="pymntdetails"
                        [(ngModel)]="selectPymntType" (change)="clearPaymemtStatus('pp')"/>
                    <label for="pp" *ngIf="!gd?.isStubEnabled">
                      <section class="paypal__pp_message">
                        <article class="paypal_pp">
                          <img src="../../assets/svg/paypal_icon.svg" alt="paypal" />
                          <img class="PPCB_title" src="../../assets/svg/paypal_msg.svg" alt="paypal" />
                          <div class="PPCB_message font_family__standard">{{t('paypal.bnpl')}}</div>
                        </article>
                      </section>
                    </label>
                </section>
                <section class="pymnt__section_pp" *ngIf="gd?.isStubEnabled">
                    <div class="pp_stub_text">
                        <input id="pp" type="radio" required value="pp" name="pymntdetails"
                            [(ngModel)]="selectPymntType" (change)="clearPaymemtStatus('pp')"/>
                        <label for="pp">
                            <span class="inline__cc font_family__standard">{{t('paypal')}}</span>
                        </label>
                    </div>
                </section>
            </div>
            <div id="payPal" *ngIf="selectPymntType === 'pp' && !gd?.isStubEnabled">
                <app-paypal></app-paypal>
            </div>
            <!-- rendered paypal stub section -->
            <div id="payPal" *ngIf="selectPymntType === 'pp' && gd?.isStubEnabled" aria-hidden="true">
                <app-paypal-stub ></app-paypal-stub>
            </div>
            <section class="pymnt__section_alp" *ngIf="isAlipayEnabled()">
                <div class="radio_inline__payments_alp">
                    <input id="alp" type="radio" required value="alp" name="pymntdetails" [(ngModel)]="selectPymntType"
                    (change)="clearPaymemtStatus('alp')"/>
                    <div class="label__inline_responsive">
                        <label for="alp">
                            <ul class="alipay-helptext font_family__default no-bullets ul-con">
                              <li>
                                <img *ngIf="!activeAP" class="alipay alipay-helptext-logo" src="../../assets/img/AlipayPlus.png" alt="Alipay+" />
                              </li>
                              <li>
                                <img *ngIf="activeAP" class="alipay-white alipay-helptext-logo" src="../../assets/img/AlipayWhite.png" alt="Alipay+" />
                              </li>
                              <li>
                                <img *ngIf="!activeAP" class="alipay-white alipay-hover alipay-helptext-logo" src="../../assets/img/AlipayWhite.png" alt="Alipay+" />
                              </li>
                            </ul>
                        </label>
                        <div class="alipay_text__description font_family__standard">
                          {{t('alipay.info.text')}}
                            <button class="learn-more" tabindex="0" role="button" (click)="openDialog()">{{t('learn.more')}}</button>
                        </div>
                    </div>
                </div>
            </section>
            <div id="alipay" *ngIf="selectPymntType === 'alp'" class="pymnt_details__cc_pymnt">
                <app-alipay [alipayStatus]="alipayStatus" [orderData]="orderData" [spcOscCode]="spcOscCode"></app-alipay>
            </div>
        </form>
    </div>
</div>
<app-accepted-payment-types [paypalEnabled]="paypalEnabled" [alipayEnabled]="alipayEnabled" [disabledPaymentMethodNames]="getBlockedPaymentMethodNames()" [spcOscCode]="spcOscCode"></app-accepted-payment-types>
