import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { Constants } from '../../constants/constants';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @ViewChild('footer') footer: ElementRef;
  @Input() spcOscCode: string;
  displayLang: string;
  
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  constructor(private translocoService: TranslocoService){}

  ngAfterViewChecked(){
    this.displayLang=this.translocoService.getActiveLang();
  }

  changeLanguage(event):void{
    this.translocoService.setActiveLang(event.target.value);
    this.displayLang=event.target.value;
  }

  navigateToMainContent(contentElementName) {
    try {
      if (contentElementName === 'top') {
        window.scroll(0, 0);
        return;
      }
      const elements = document.getElementById(contentElementName);
      if (elements) {
        elements.scrollIntoView();
        elements.focus();
      }
    } finally {
        //need to execute
    }
  }

  navigateToFooter(footerName) {
    const bottom = document.getElementById(footerName);
    if (bottom) {
      bottom.scrollIntoView();
      bottom.focus();
    }
  }




}