import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Transaction } from '../models/transaction';
import { CountryRegionService } from '../services/country-region.service';
import { Constants } from '../constants/constants';
import { GlobalAppData } from '../models/global-app-data';

@Component({
  selector: 'app-billing-address-information',
  templateUrl: './billing-address-information.component.html',
  styleUrls: ['./billing-address-information.component.css'],
})
export class BillingAddressInformationComponent implements OnInit {
  @Input() formGroupName: string;
  @Input() orderData: Transaction;
  form: FormGroup;
  selectedCountry: string;
  countryList: any = [];
  regionList: any;
  @Input()
  requiredFields:any=[];
  @Input()
  addrLabel='billing.same.shipping.address';
  
  // Global OSC codes
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;
  @Input() spcOscCode: String;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private countryRegionService: CountryRegionService,
    public gd: GlobalAppData
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.countryList = this.countryRegionService.getCountries();
    for(const reqFld of this.requiredFields){
      this.form.controls[reqFld].setValidators(Validators.required);
    }
  }

  changeCountry(): void {
    this.form.get('region').clearValidators();
    this.form.get('zipcode').clearValidators();
    this.form.controls['region'].reset();
    this.form.controls['zipcode'].reset();
    this.form.controls['region'].setValue(null);
    this.form.controls['zipcode'].setValue(null);
    this.form.controls['region'].enable();
    this.form.controls['zipcode'].enable();
    this.selectedCountry = this.form.get('country').value;
    this.regionList = []
    if (this.selectedCountry === 'US' || this.selectedCountry === 'CA') {
      const pattern = this.selectedCountry === 'US' ? Constants.ZIPCODE_US_VALIDATOR_PATTERN : Constants.ZIPCODE_CA_VALIDATOR_PATTERN;
      const minLength = this.selectedCountry === 'US' ? 5 : 6;
      this.form.get('region').setValidators(Validators.required);
      this.form.get('zipcode').setValidators([Validators.pattern(pattern), Validators.required, Validators.minLength(minLength), Validators.maxLength(9)]);
      this.regionList = this.countryRegionService.loadCountryStates(this.selectedCountry);
    }
  }

  sameadrchkchange(event) {
    if (event.target.checked && this.orderData) {
      this.form.controls['streetAddress1']?.setValue(
        this.orderData.shippingAddress.addressLine1
      );
      this.form.controls['streetAddress2']?.setValue(
        this.orderData.shippingAddress.addressLine2
      );
      this.form.controls['city']?.setValue(this.orderData.shippingAddress.city);
      this.form.controls['company']?.setValue(
        this.orderData.shippingAddress.company
      );
      this.form.controls['country']?.setValue(
        this.orderData.shippingAddress.country
      );
      this.changeCountry();
      this.form.controls['zipcode']?.setValue(
        this.orderData.shippingAddress.postalCode
      );
      setTimeout(()=>{
        this.form.controls['region']?.setValue(
          this.orderData.shippingAddress.region
        );
      },100);

      if (this.orderData.shippingContact?.phoneNumber) {
        this.form.controls['phoneNumber']?.setValue(
          this.orderData.shippingContact?.phoneNumber
        );
      }
      if (this.orderData.shippingContact?.email) {
        this.form.controls['email']?.setValue(
          this.orderData.shippingContact?.email
        );
      }
    } else {
      this.form.reset()
      this.form.controls['zipcode']?.disable();
      this.form.controls['region']?.disable();
      this.selectedCountry = null;
    }
  }

  isRequiredField(fieldName: string) {
    if (this.requiredFields && this.requiredFields.length > 0) {
      return this.requiredFields.indexOf(fieldName) >= 0;
    } else {
      return false;
    }
  }
}
