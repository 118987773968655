import { Component, ViewChild } from '@angular/core';

import { Transaction } from '../models/transaction';
import { PaymentResponse } from '../models/payment-response';
import { OrderService } from '../services/order.service';
import { GlobalAppData } from '../models/global-app-data';
import { MerchantResponseData, PaymentActionData } from '../models/merchant-models';
import { LoadingService } from '../services/loading.service';
import {TransactionStatus} from '../models/transaction-status';
import { SegmentService } from '../services/segment.service';
import { EventTypes } from '../models/enums/event-types';

@Component({
  selector: 'app-merchant-response',
  templateUrl: './merchant-response.component.html',
  styleUrls: ['./merchant-response.component.css']
})
export class MerchantResponseComponent {
  paymentActionData: PaymentActionData;
  orderTx?: Transaction;
  paymentResponse?: PaymentResponse;
  paymentMethodCode?: string;
  pgResponseData?: Map<any, any>;
  redirectType: string;
  actionUrl;
  @ViewChild('paymentActionDataForm') paymentActionDataForm;

  constructor(private odService: OrderService,
    private globalAppData: GlobalAppData,
    private _loading: LoadingService,
    private ss: SegmentService) {
  }

  sendResponseToMerchant(paymentMethodCode: string, merchantResponseData: MerchantResponseData): void {
    this.actionUrl = merchantResponseData.url;
    this.fetchPgResponseAndSubmitForm(merchantResponseData);

  }

  private fetchPgResponseAndSubmitForm(merchantResponseData: MerchantResponseData): any {
      this._loading.setLoading(true, 'internal');
      const obs = this.odService.fetchPgResponse();
      obs.subscribe({next:(data: string) => {
        this.pgResponseData = this.convertStringToMap(data);
        this._loading.setLoading(false, 'internal');
        this.handleRedirectBetweenAdminUiAndProgramApps(merchantResponseData.url);
      }, error:(err) => {
        this._loading.setLoading(false, 'internal');
      }});
  }

  private handleRedirectBetweenAdminUiAndProgramApps(redirectUrl: string) {
    sessionStorage.clear();
    this.ss.track(EventTypes.pmtCompleted, null, null, this.pgResponseData);
    if (redirectUrl && redirectUrl.indexOf('virtual-terminal') >= 0) {
      location.href = `${redirectUrl}?t=${this.globalAppData.transId}&status=${this.pgResponseData.get('decision')}&sourceType=${this.globalAppData.sourceType}`;
    } else {
      this.submitForm();
    }
  }

  private convertStringToMap(data: any): Map<any, any> {
    // construct map for all request fields
    const map: Map<any, any> = new Map<any, any>();
    let jsonObj;
    if (typeof data === 'string') {
      jsonObj = JSON.parse(data as string);
    } else {
      jsonObj = data;
    }
    const keys = Object.keys(jsonObj);
    const values = Object.values(jsonObj);
    for (let i = 0; i <= Object.keys(jsonObj).length; i++) {
      map.set(keys[i], values[i]);
    }
    return map;
  }

  private submitForm(): void {
    sessionStorage.clear();
    const timeout = 'PAYPAL' === this.pgResponseData?.get('methodOfPayment').toUpperCase() ? 3000 : 100;
    this._loading.setLoading(true, 'internal');
    setTimeout(() => {
      const form = this.paymentActionDataForm;
      form.nativeElement.submit();
      this._loading.setLoading(false, 'internal');
    }, timeout);
  }

  private getMerchantSuccessURL(pgResponse: any): string {
    let merchantSuccessURL = pgResponse.get('merchantSuccessURL');
    if (!merchantSuccessURL) {
      merchantSuccessURL = this.globalAppData.oscConfig.successUrl;
    }
    return merchantSuccessURL;
  }

  private getMerchantRejectURL(pgResponse: any): string {
    let merchantRejectURL = pgResponse.get('merchantRejectURL');
    if (!merchantRejectURL) {
      merchantRejectURL = this.globalAppData.oscConfig.rejectUrl;
    }
    return merchantRejectURL;
  }

  private getMerchantErrorURL(pgResponse: any): string {
    let merchantErrorURL = pgResponse.get('merchantErrorURL');
    if (!merchantErrorURL) {
      merchantErrorURL = this.globalAppData.oscConfig.errorUrl;
    }
    return merchantErrorURL;
  }

  // eslint-disable-next-line
  async redirectToMerchantSuccessURL(paymentMethodCode: string, response: PaymentResponse, redirectType?: string) {
    const obs = this.odService.fetchPgResponse();
    obs.subscribe({next:(data: string) => {
      this.pgResponseData = this.convertStringToMap(data);
      this.actionUrl = this.getMerchantSuccessURL(this.pgResponseData);

      if (redirectType === 'redirectForOscSessionExists') {
        this.pgResponseData.set('pgmResponseCode', '501');
      }
      this._loading.setLoading(false, 'internal');
      this.handleRedirectBetweenAdminUiAndProgramApps(this.actionUrl);
    },
      error:(err) => {
        this._loading.setLoading(false, 'internal');
      }});
  }

  private updateTransactionAndRedirect(paymentMethodCode: string, response: PaymentResponse, pgmCode: string, pgmMessage: string, decision: string, transaction_status?: string) {
    const transactionStatus = new TransactionStatus();
    transactionStatus.orderTransactionId = this.globalAppData.transId;
    if (decision) {
      transactionStatus.decision = decision;
    }
    transactionStatus.pgmResponseCode = pgmCode;
    transactionStatus.pgmResponseMessage = pgmMessage;
    transactionStatus.transactionStatus = transaction_status ? transaction_status : transactionStatus.transactionStatus;
    const obs = this.odService.updateTransactionStatus(transactionStatus);
    obs.subscribe({next:data => {
      if (decision == 'REJECT') {
        this._redirectToMerchantRejectURL(paymentMethodCode, response);
      }else if (decision == 'TIMEOUT'){
        this.redirectToTimeoutURL(paymentMethodCode, response);
      }
    }, error:error => {
      console.log('error while updating the tx status');
    }});
  }

  redirectToMerchantRejectURL(paymentMethodCode: string, response: PaymentResponse): void {
    let isRedirected = false;
    if (this.redirectType === 'backToOrder') {
      sessionStorage.clear();
      window.sessionStorage.clear();
      isRedirected = true;
      this.updateTransactionAndRedirect(paymentMethodCode, response, '502', 'Customer return requested.', 'REJECT');
    }
    if (this.redirectType === 'onBrowserClose') {
      sessionStorage.clear();
      window.sessionStorage.clear();
      isRedirected = true;
      this.updateTransactionAndRedirect(paymentMethodCode, response, '502', 'Customer closed browser.', 'REJECT');
    }
    if (this.redirectType === 'ccSwitchedPayment') {
      isRedirected = true;
      this.updateTransactionAndRedirect(paymentMethodCode, response, '150', 'Transaction was not completed', 'REJECT', 'ERROR');
    }
    if (this.redirectType === 'paymentBack') {
      isRedirected = true;
      this.updateTransactionAndRedirect(paymentMethodCode, response, '476', 'User exited Payer Authentication', 'REJECT');
    }
    if (this.redirectType === 'ccIncomplete') {
      isRedirected = true;
      this.updateTransactionAndRedirect(paymentMethodCode, response, '150', 'Transaction was not completed', 'REJECT', 'INCOMPLETE');
    }
    if (this.redirectType === 'timeout') {
      isRedirected = true;
      this.updateTransactionAndRedirect(paymentMethodCode, response, '151', 'SESSION TIMEOUT', 'TIMEOUT','INCOMPLETE');
    }
    if (!isRedirected) {
      this._redirectToMerchantRejectURL(paymentMethodCode, response);
    }
  }

  _redirectToMerchantRejectURL(paymentMethodCode: string, response: PaymentResponse): void {
    const obs = this.odService.fetchPgResponse();
    obs.subscribe({next:(data: string) => {
      this.pgResponseData = this.convertStringToMap(data);
      this.actionUrl = this.getMerchantRejectURL(this.pgResponseData);
      if (this.redirectType === 'backToOrder') {
        this.pgResponseData.set('pgmResponseCode', '502');
        this.pgResponseData.set('pgmResponseMessage', 'Customer return requested');
        this.pgResponseData.set('decision', 'REJECT');
      }
      this._loading.setLoading(false, 'internal');
      this.handleRedirectBetweenAdminUiAndProgramApps(this.actionUrl);
    },
      error:(err)  => {
        this._loading.setLoading(false, 'internal');
      }});
  }

  redirectToMerchantErrorURL(paymentMethodCode: string, response: PaymentResponse): void {
    const obs = this.odService.fetchPgResponse();
    obs.subscribe({next:(data: string) => {
      this.pgResponseData = this.convertStringToMap(data);
      this.actionUrl = this.getMerchantErrorURL(this.pgResponseData);

      this._loading.setLoading(false, 'internal');
      this.handleRedirectBetweenAdminUiAndProgramApps(this.actionUrl);
    },
      error:err => {
        this._loading.setLoading(false, 'internal');
      }});
  }

  backToOrder(reasonType: string, paymentMethodCode: string, response: PaymentResponse) {
    this.redirectType = reasonType;
    this.redirectToMerchantRejectURL(paymentMethodCode, response);
  }

  onBrowserClose(reasonType: string, paymentMethodCode: string, response: PaymentResponse) {
    this.redirectType = reasonType;
    this.redirectToMerchantRejectURL(paymentMethodCode, response);
  }

  paymentBackAction(reasonType: string, paymentMethodCode: string, response: PaymentResponse) {
    this.redirectType = reasonType;
    this.redirectToMerchantRejectURL(paymentMethodCode, response);
  }
  redirectToTimeoutURL(paymentMethodCode: string, response: PaymentResponse): void {
    const obs = this.odService.fetchPgResponse();
    obs.subscribe({next:(data: string) => {
        this.pgResponseData = this.convertStringToMap(data);
        this.actionUrl = this.getMerchantTimeoutURL(this.pgResponseData);
          this.pgResponseData.set('pgmResponseCode' , '151');
          this.pgResponseData.set('pgmResponseMessage' , 'SESSION TIMEOUT');
          this.pgResponseData.set('decision' , 'TIMEOUT');
          this._loading.setLoading(false, 'internal');
          this.handleRedirectBetweenAdminUiAndProgramApps(this.actionUrl);
      },
      error:err => {
        this._loading.setLoading(false, 'internal');
      }});
  }

  getMerchantTimeoutURL(pgResponse: any): string {
    let merchantTimeoutURL: string;
    merchantTimeoutURL = pgResponse.get('merchantTimeoutURL');
    if (!merchantTimeoutURL) {
      merchantTimeoutURL = this.globalAppData.oscConfig.timeoutUrl;
    }
    return merchantTimeoutURL;
  }
}

